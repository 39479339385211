import { useView } from "@/context/ViewContext"
import ReadOnlyEditor from "./readOnlyEditor"
import ReadWriteEditor from "./readWriteEditor"
import {
  useDocumentContext,
} from "@/components/left/DocumentContext"
import { PDF1 } from "@/components/PdfWrapper";

const colors = {
  // previously tried gradient 011 at https://webgradients.com/
  // -> https://coolors.co/gradient-palette/ and take colors in between
  // lightGreen: "linear-gradient(120deg, #BFF58699 0%, #ABED9499 100%)",
  lightGreen: "rgb(134, 239, 172, 0.6)",
  darkGreen: "rgb(134, 239, 172, 1.0)",
  darkRed: "transparent", //"#FF4D4D",
  lightRed: "transparent", //"#FFB3B3",
  lightGray: "#d8dfe6", // that's "#3C608333" with alpha=0.2, //"#3C608366",
  darkGray: "transparent", //"#99B5D1",
}

export default function Right({ }) {
  // const { splitView } = useView()
  const splitView = false
  const { document, htmlFile } = useDocumentContext()

  return (
    <>
      <div className={`py-0 mr-0 max-h-screen w-1/2 overflow-y-scroll`}>
        <div
          className={` ${
            splitView ? "p-8" : "px-0 pb-0"
          } bg-bgSecondary rounded-10 flex flex-col`}
        >
          <PDF1 />
        </div>
      </div>
      {/* {htmlFile && (
        <ReadOnlyEditor
          keywords={keywords}
          setMatches={setMatches}
          activeMatch={activeMatch}
        />
      )} */}
      {/* {splitView && <ReadWriteEditor />} */}
    </>
  )
}
