import { CheckIcon, Loader2, PaperclipIcon, PencilIcon, XIcon } from "lucide-react";
import { useDocumentContext } from "@/components/left/DocumentContext";
import { useCallback, useEffect, useState } from "react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { useAssignDocumentToPeopleMutation, useGetProjectCollaboratorsQuery, useGetProjectPartiesQuery, useSetDocumentStatusMutation, useUpdateDocumentEntitiesMutation } from "@/api/resources";
import { PartyPickerCombobox } from "./OverviewTab/party-picker";
import { Textarea } from "../ui/textarea";
import { toast } from "sonner";
import { Status } from "@/elements";
import { STATUS_DROPDOWN_VALUES } from "@/types/status";
import { DropdownValue } from "@/elements/status";
import { findStatus } from "@/utils/status";
import { DatePicker } from "./OverviewTab/date-picker";
import { EditableField } from "./OverviewTab/editable-field";
import UserPicker from "./OverviewTab/user-picker";
import { UserSchema } from "@/api/resources/types";


export default function DocumentOverview() {
  const { document } = useDocumentContext()
  const [updateDocumentEntities, { isLoading: isUpdatingComment }] = useUpdateDocumentEntitiesMutation()
  const { data: projectParties } = useGetProjectPartiesQuery(document?.project_id ?? "", { skip: !document?.project_id })
  const { data: projectUsers } = useGetProjectCollaboratorsQuery(document?.project_id ?? "", { skip: !document?.project_id })
  const [isEditingParty, setIsEditingParty] = useState(false)
  const [editedParties, setEditedParties] = useState(document?.parties ?? [])

  const [comments, setComments] = useState("")
  const [docStatus, setDocStatus] = useState<DropdownValue | null>(null)
  const [setDocumentStatus, result] = useSetDocumentStatusMutation()  // TODO: use optimistic update instead of react state
  const [dateSigned, setDateSigned] = useState<Date | null>(null)

  const [selectedUsers, setSelectedUsers] = useState<UserSchema[]>(document?.assigned_to ?? [])
  const [assignDocumentToPeople, { isLoading: isAssigningDocumentToPeople }] = useAssignDocumentToPeopleMutation()

  // TODO: make "assignDocumentToPeople" change "documentMetadata" when it returns 200

  useEffect(() => {
    setComments(document?.comment ?? "")
    setDateSigned(document?.date_signed ? new Date(document.date_signed) : null)
  }, [document])

  useEffect(() => {
    // TODO: we just want to do it once on load though?
    if (document && document.status) {
      setDocStatus(findStatus(document.status))
    }
  }, [document])

  const setStatusAndUpdate = (newStatus: DropdownValue) => {
    const documentId = document?.document_id
    if (!documentId) {
      return
    }
    setDocStatus(newStatus)
    setDocumentStatus({ document_id: documentId, status: newStatus.id })
  }

  if (!document) {
    return <></>
  }

  return (
    <div>
      <div className="mt-2 px-4 sm:px-0">
        <h3 className="text-base/7 font-semibold text-gray-900">Filename</h3>
        <p className="mt-1 max-w-2xl text-sm/6 text-gray-500">{document.display_name}.{document.file_type}</p>
      </div>
      <div className="mt-4 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-900">Title</dt>
            <dd>
              <EditableField value={document.title ?? ""} onSave={(value) => {
                updateDocumentEntities({ document_id: document.document_id, fields: { title: value } })
              }} />
            </dd>
          </div>
          {
            document?.parties && !isEditingParty && 
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Parties</dt>
                {document.parties.map((party, index) => (
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-start-2 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                  {party}
                  <button onClick={() => setIsEditingParty(true)}>
                    <PencilIcon className="w-4 h-4" />
                  </button>
                </dd>))}
              </div>
          }
          {
            document?.parties && isEditingParty && <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm/6 font-medium text-gray-900">Parties</dt>
              <dd className="flex flex-col gap-2 w-full sm:col-span-2">
                {editedParties.map((party, index) => (
                  <div key={index} className="flex items-center gap-2 bg-gray-100 rounded-md p-2 w-full">
                    <span>{party}</span>
                    <Button variant="ghost" size="icon" className="ml-auto" onClick={() => {
                      setEditedParties(editedParties.filter((p) => p !== party))
                    }}>
                      <XIcon className="w-4 h-4" />
                    </Button>
                  </div>
                ))}
                <PartyPickerCombobox parties={projectParties ?? []} onSelect={(party) => {
                  if (!editedParties.includes(party)) {
                    setEditedParties([...editedParties, party])
                  }
                }} />
                <div className="flex items-center gap-2 mr-auto">
                  <Button onClick={() => {
                    updateDocumentEntities({ document_id: document.document_id, fields: { parties: editedParties } })
                    setIsEditingParty(false)
                  }}>Save</Button>
                  <Button variant="secondary" onClick={() => {
                    setEditedParties(document?.parties ?? [])
                    setIsEditingParty(false)
                  }}>
                    Cancel
                  </Button>
                </div>
                </dd>
            </div>
          }
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-900">Signature date</dt>
            <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
            {/* <DatePicker date={dateSigned ?? null} setDate={(date) => {
              setDateSigned(date)
              updateDocumentEntities({ document_id: document.document_id, fields: { date_signed: `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}` } })
            }} /> */}
            {dateSigned ? `${dateSigned.toLocaleDateString()} (latest date found)` : "Signature not found"}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-900">Assigned to</dt>
            <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
              <UserPicker
                users={projectUsers ?? []}
                selectedUsers={selectedUsers}
                onSelect={(user) => {
                  if (!selectedUsers.includes(user)) {
                    const newSelectedUsers = [...selectedUsers, user]
                    setSelectedUsers(newSelectedUsers)
                    assignDocumentToPeople({ document_id: document.document_id, user_ids: newSelectedUsers.map((u) => u.user_id) })
                  }
                }}
                onRemove={(userId) => {
                  if (selectedUsers.some((u) => u.user_id === userId)) {
                    const newSelectedUsers = selectedUsers.filter((u) => u.user_id !== userId)
                    setSelectedUsers(newSelectedUsers)
                    assignDocumentToPeople({ document_id: document.document_id, user_ids: newSelectedUsers.map((u) => u.user_id) })
                  }
                }} />
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-900">Status</dt>
            <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
              <Status
                values={STATUS_DROPDOWN_VALUES}
                selectedValue={docStatus}
                setSelectedValue={setStatusAndUpdate}
              />
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-900">Comments</dt>
            <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
              <Textarea value={comments} onChange={(e) => setComments(e.target.value)} />
              <Button className="mt-2" onClick={() => {
                updateDocumentEntities({ document_id: document.document_id, fields: { comment: comments } }).unwrap().then(() => {
                  toast.success("Comment saved")
                }).catch(() => {
                  toast.error("Error saving comment")
                })
              }} disabled={isUpdatingComment}>
                Save
              </Button>
            </dd>
          </div>
          {/* <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-900">Summary</dt>
            <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
              Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
              qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
              pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
            </dd>
          </div> */}
          {/* <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-900">Attachments</dt>
            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6">
                  <div className="flex w-0 flex-1 items-center">
                    <PaperclipIcon aria-hidden="true" className="size-5 shrink-0 text-gray-400" />
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">{document?.document_id}.{document?.file_type}</span>
                      <span className="shrink-0 text-gray-400">4.5mb</span>
                    </div>
                  </div>
                  <div className="ml-4 shrink-0">
                    <span className="font-medium text-indigo-600 hover:text-indigo-500">
                      Download
                    </span>
                  </div>
                </li>
              </ul>
            </dd>
          </div> */}
        </dl>
      </div>
    </div>
  )
}
