import * as React from "react"

import { cn } from "@/lib/utils"
import {
  NavigationMenu,
  NavigationMenuContent, NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle
} from "@/components/ui/navigation-menu";
import { ddCriteria } from "@/data/criteria";
import { useDocumentContext } from "@/components/left/DocumentContext";


export function DocumentNavigationMenu({ tab, setTab }) {
  const { setSemanticSearchClass, predictionsInfo } = useDocumentContext()

  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem onClick={() => setSemanticSearchClass(null)}>
          <NavigationMenuLink className={navigationMenuTriggerStyle()}>
            Overview
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Edit &amp; Annotate</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
              {predictionsInfo.map((component) => {
                const key = component.class_id
                return (
                  <ListItem
                    key={key}
                    title={component.class_name}
                    onClick={() => {setSemanticSearchClass(key); setTab("edit")}}
                  >
                  {/* {component.description} */}
                  {component.labeled_by ? (
                    <span className="text-sm text-gray-500">
                      Last updated by: {component.labeled_by.first_name} {component.labeled_by.last_name}
                    </span>
                  ) : (
                    <span className="text-sm text-gray-500">
                      Not annotated by human yet
                    </span>
                  )}
                  </ListItem>
                )
              })}
            </ul>
            {predictionsInfo.length === 0 && (
              <div className="text-sm text-gray-500">
                No classes were found for this project
              </div>
            )}
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem className="hidden">
          <NavigationMenuTrigger>Custom search</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid gap-3 p-4 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
              <li className="row-span-3">
                <NavigationMenuLink asChild>
                  <a
                    className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md"
                    href="/"
                  >
                    {/*<Icons.logo className="h-6 w-6" />*/}
                    <div className="mb-2 mt-4 text-lg font-medium">
                      shadcn/ui
                    </div>
                    <p className="text-sm leading-tight text-muted-foreground">
                      Beautifully designed components built with Radix UI and
                      Tailwind CSS.
                    </p>
                  </a>
                </NavigationMenuLink>
              </li>
              <ListItem href="/docs" title="Introduction">
                Re-usable components built using Radix UI and Tailwind CSS.
              </ListItem>
              <ListItem href="/docs/installation" title="Installation">
                How to install dependencies and structure your app.
              </ListItem>
              <ListItem href="/docs/primitives/typography" title="Typography">
                Styles for headings, paragraphs, lists...etc
              </ListItem>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuIndicator />
      </NavigationMenuList>
    </NavigationMenu>
  )
}

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
  >(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-gray-100 hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})
ListItem.displayName = "ListItem"
