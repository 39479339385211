import { useMemo, useState } from "react"
import { useDocumentContext } from "../DocumentContext"
import { DocumentPredictionsInfoSchema } from "@/api/resources/types"
import { useGetDocumentPredictionsForDisplayQuery } from "@/api/resources"

function Chip ({ text }: {text: string}) {
    return <div className="rounded px-1 bg-blue-600 text-white text-xs">{text}</div>
}

export function SemanticSearchResults ({ selectedClasses }: { selectedClasses: DocumentPredictionsInfoSchema[] }) {
    const { document, parsedDocument, activeMatch, setActiveMatch } = useDocumentContext()

    const shownClassIds = useMemo(() => {
        return selectedClasses?.map(x => x.class_id) ?? []
    }, [selectedClasses])

    const { data: backendPredictions } = useGetDocumentPredictionsForDisplayQuery({
        document_id: document?.document_id ?? "",
        class_ids: shownClassIds
      }, { skip: !document?.document_id })

    const data = parsedDocument?.text_chunks?.map((x, i) => ({
        index: i,
        matchIndex: i,
        text: x.text,
        classes: [] as string[]
    }))

    data && backendPredictions && Object.entries(backendPredictions)?.filter(x => shownClassIds.includes(x[0]))?.forEach((x) => {
        const [key, value] = x  // key: text chunk index, value: {logits: number[], predictions: number[], user_labels: number[]}
        if (typeof value !== "string") {
            if (value.user_labels) { 
                // TODO: indicate somehow that this is user-labeled
                value.user_labels.forEach((value, index) => {
                    if (value != 0) data[index].classes.push(key)
                })
            } else {
                value.predictions.forEach((value, index) => {
                    if (value != 0) data[index].classes.push(key)
                })
            }
        }
    })

    return <div>
        {data?.filter((x) => x.classes.length > 0).map((x) => (
            <div 
                className={`p-3 my-2 rounded border hover:bg-zinc-100 ${activeMatch === x.matchIndex ? 'bg-zinc-100' : 'bg-white'}`}
                onClick={() => {
                    setActiveMatch(x.matchIndex)
                }}
            >
                <div className="flex gap-1">
                    {x.classes.map(cls => <Chip text={cls.replaceAll("_", " ")}/>)}
                </div>
                <span>{x.text}</span>
            </div>
        ))}
    </div>
}