import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible"
import { Label } from "@/components/ui/label"
import { CaretSortIcon } from "@radix-ui/react-icons"
import { Checkbox } from "@/components/ui/checkbox"

interface CheckboxParams {
  items: {
    key: string
    value: string
  }[]
  checkedItems: string[]
  toggleChecked: (x: string) => void
  label: string
}

export function CheckboxSelect({
  items,
  checkedItems,
  toggleChecked,
  label,
}: CheckboxParams) {
  return (
    <Collapsible className="space-y-2">
      <CollapsibleTrigger className="flex justify-between w-full">
        <Label>{label}</Label>
        <CaretSortIcon className="h-4 w-4" />
      </CollapsibleTrigger>
      <CollapsibleContent>
        {items?.map((item) => (
          <div key={item.key} className="flex items-center space-x-2 py-0.5">
            <Checkbox
              id={item.key}
              checked={checkedItems.includes(item.key)}
              onCheckedChange={() => toggleChecked(item.key)}
            />
            <Label htmlFor={item.key}>{item.value}</Label>
          </div>
        ))}
      </CollapsibleContent>
    </Collapsible>
  )
}
