import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { toast } from "sonner"

import { Button } from "@/components/ui/button"
import { Input, ToggleableInput } from "@/components/ui/input"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { useAppSelector } from "@/redux/hooks"
import LoginIllustration from "@/assets/images/LoginIllustration.svg?react"
import { useResetPasswordMutation } from "@/api/resources"

const setupSchema = z.object({
  password: z.string().min(8, { message: "Password must be at least 8 characters" }),
  confirmPassword: z.string()
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"],
})

function SetupForm({ email, token }: { email: string, token: string }) {
  const [resetPassword, { isLoading }] = useResetPasswordMutation()
  const navigate = useNavigate()

  const form = useForm<z.infer<typeof setupSchema>>({
    resolver: zodResolver(setupSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  })

  const onSubmit = async (values: z.infer<typeof setupSchema>) => {
    if (!token || !email) {
      toast.error("Invalid setup link")
      return
    }
    const loadingToastId = toast.loading("Setting up your account...")
    try {
      await resetPassword({
        email,
        token,
        password: values.password
      }).unwrap()

      toast.success("Account setup successful!", {
        description: "You can now log in with your email and password.",
        id: loadingToastId,
      })
      navigate("/login")
    } catch (error: any) {
      toast.error("Setup failed.", {
        description: error?.data?.detail ? 
            ("An error occurred while setting up your account: " + error.data.detail) 
        : 
            "An error occurred while setting up your account.",
        id: loadingToastId,
      })
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid gap-4">
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <ToggleableInput
                    required
                    autoComplete="new-password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Confirm Password</FormLabel>
                <FormControl>
                  <ToggleableInput
                    required
                    autoComplete="new-password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" className="w-full">
            Set up account
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default function SetupAccountPage() {
  const accessToken = useAppSelector((state) => state.auth.accessToken)
  const [searchParams] = useSearchParams()
  const email = searchParams.get("email")
  const token = searchParams.get("token")

  if (accessToken !== null) {
    return <Navigate to="/" />
  }

  if (!email || !token) {
    return <div className="text-center h-full items-center justify-center flex">
        Invalid setup link
    </div>
  }

  return (
    <div className="w-full lg:grid lg:min-h-screen lg:grid-cols-2">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-5xl font-300 font-outfit">Set up account</h1>
            <div className="pb-5" />
          </div>
          <SetupForm email={email} token={token} />
          <div className="mt-4 text-center text-sm">
            Already have an account?{" "}
            <Link to="/login" className="underline">
              Log in
            </Link>
          </div>
        </div>
      </div>
      <div className="hidden bg-muted lg:block lg:h-full p-4">
        <div className="flex flex-col h-full p-10 bg-bgSecondary rounded-3xl">
          <LoginIllustration className="p-10 flex-grow-2 mx-auto" />
          <div className="flex-grow pl-10 flex-col justify-center">
            <h3 className="font-400 text-25 font-outfit">
              Welcome to Pactus <span className="text-blue-200">AI</span>!
            </h3>
            <p className="pt-2 text-text-neutral">
              Please set up your password to complete your account registration.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
