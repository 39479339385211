import * as React from "react"
import { Check, ChevronsUpDown, X } from "lucide-react"

import { cn } from "@/lib/utils"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { UserSchema } from "@/api/resources/types"

type User = UserSchema

interface UserPickerProps {
  users: User[]
  selectedUsers: User[]
  onSelect: (user: User) => void
  onRemove: (userId: string) => void
  placeholder?: string
}

export function UserPicker({
  users,
  selectedUsers,
  onSelect,
  onRemove,
  placeholder = "Select users...",
}: UserPickerProps) {
  const [open, setOpen] = React.useState(false)
  const [search, setSearch] = React.useState("")

  // Filter out already selected users and filter by search term
  const filteredUsers = users.filter(
    (user) =>
      !selectedUsers.some((selected) => selected.user_id === user.user_id) &&
      (user.first_name.toLowerCase().includes(search.toLowerCase()) ||
        user.last_name.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase())),
  )

  return (
    <div className="space-y-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button variant="outline" role="combobox" aria-expanded={open} className="w-full justify-between">
            {placeholder}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0" align="start">
          <Command>
            <CommandInput placeholder="Search users..." value={search} onValueChange={setSearch} />
            <CommandList>
              <CommandEmpty>No users found.</CommandEmpty>
              <CommandGroup>
                {filteredUsers.map((user) => (
                  <CommandItem
                    key={user.user_id}
                    value={user.first_name + " " + user.last_name}
                    onSelect={() => {
                      onSelect(user)
                      setOpen(false)
                    }}
                    className="flex items-center gap-2"
                  >
                    <Avatar className="h-6 w-6">
                      <AvatarImage src={user.avatar ?? ""} alt={""} />
                      {/* <AvatarFallback>{user.name.charAt(0).toUpperCase()}</AvatarFallback> */}
                    </Avatar>
                    <div className="flex flex-col">
                      <span>{user.first_name + " " + user.last_name}</span>
                      <span className="text-xs text-muted-foreground">{user.email}</span>
                    </div>
                    <Check
                      className={cn(
                        "ml-auto h-4 w-4",
                        selectedUsers.some((selected) => selected.user_id === user.user_id) ? "opacity-100" : "opacity-0",
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>

      {selectedUsers.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {selectedUsers.map((user) => (
            <Badge key={user.id} variant="secondary" className="flex items-center gap-1 px-3 py-1">
              <Avatar className="h-5 w-5">
                <AvatarImage src={user.avatar ?? ""} alt={""} />
                {/* <AvatarFallback>{user.name.charAt(0).toUpperCase()}</AvatarFallback> */}
              </Avatar>
              <span>{user.first_name + " " + user.last_name}</span>
              <Button
                variant="ghost"
                size="icon"
                className="h-4 w-4 p-0 ml-1 hover:bg-transparent"
                onClick={() => onRemove(user.user_id)}
              >
                <X className="h-3 w-3" />
                <span className="sr-only">Remove {user.first_name + " " + user.last_name}</span>
              </Button>
            </Badge>
          ))}
        </div>
      )}
    </div>
  )
}

export default UserPicker
