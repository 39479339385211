import { useEffect, useState } from "react"

import { Modal } from "@/elements"
import ProjectHeader from "./ProjectHeader"
import { useLazyExportProjectDocumentsQuery } from "@/api/resources"
import {
  useOnUploadFileChange, useProjectContext,
} from "@/components/Project/ProjectContext";
import { UploadFile } from "@/components/UploadFile"
import { toast } from "sonner"
import { FiltersView, Sidebar, SidebarButton, useFilterContext } from "./Filtering";
import KeywordsTab from "@/components/Project/project/main/keywordsTab";
import SemanticTab from "@/components/Project/project/main/semanticTab";


export default function Project() {
  const project = useProjectContext()
  const { provisions } = useFilterContext()
  const [exportDocuments] = useLazyExportProjectDocumentsQuery()

  const [openDocUploading, setOpenDocUploading] = useState<boolean>(false)
  const [openShare, setOpenShare] = useState<boolean>(false)
  const { handleFileChange, chosenFiles } = useOnUploadFileChange()

  const toggleDropdown = () => setOpenDocUploading((prev) => !prev)
  const toggleShare = () => setOpenShare((prev) => !prev)

  useEffect(() => {
    // if chosen files have changed, close the upload dropdown
    setOpenDocUploading(false)
  }, [chosenFiles])

  // TODO: we should have a loading state to avoid showing EmptyProject
  return (
    <div className="flex w-full h-full">
      <Sidebar />
      <div className="basis-0 w-0 grow h-full">
      <ProjectHeader
        project={project}
        toggleDropdown={toggleDropdown}
        onExportProject={async () => {
          const loadingToastId = toast.loading("Exporting project...")
          const result = await exportDocuments({
            project_id: project.project_id,
            provisions: provisions ? provisions.map(p => p.toLowerCase().replaceAll(" ", "_")) : null,
          })

          if (result.isError) {
            toast.error("Failed to export project.", {
              description: "Please try again later.",
              id: loadingToastId,
            })
          } else {
            toast.success("Project exported successfully.", {
              id: loadingToastId,
            })
          }
        }}
      />
      {project && (
        <div className="py-4 wrapper m-auto overflow-y-scroll">
            <FiltersView />
              {
                provisions && provisions.length > 0 ? <SemanticTab /> : <KeywordsTab />
              }
        </div>
      )}

      <Modal
        cls="max-w-xl"
        title="Upload Documents"
        isOpen={openDocUploading}
        setOpen={setOpenDocUploading}
      >
        <UploadFile onUpload={handleFileChange} dashed multiple />
      </Modal>
    </div>
    </div>
  )
}
