import { API_URL } from "@/config"

import { Icon } from "@/assets"
import { Modal } from "@/elements"
import { useState } from "react"
import MyLibrary from "../myLibrary"
import { useAppSelector } from "@/redux/hooks"
import { useDocumentContext } from "@/components/left/DocumentContext"
import { useNavigate } from "react-router"

export default function DocumentHeader({ onSavedSearchSelected }) {
  const navigate = useNavigate()
  const [openLibrary, setOpenLibrary] = useState<boolean>(false)
  const closeLibraryModal = () => setOpenLibrary(false)
  const { documentId, document: documentMeta } = useDocumentContext()

  const accessToken = useAppSelector((state) => state.auth.accessToken)

  const onDownload = async () => {
    const url = `${API_URL}/documents/${documentId}`
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/octet-stream",
      },
    }
    // another option would be to have the backend generate a download link
    const response = await fetch(url, options)
    // Extract filename from the header
    const filenameHeader = response.headers.get("Content-Disposition")
    const filename = filenameHeader.replace(/.*filename="(.*)".*/, "$1")
    // Get file content and make the browser save it
    const blob = await response.blob()
    const fileURL = window.URL.createObjectURL(blob)
    let aLink = document.createElement("a")
    aLink.href = fileURL
    aLink.download = filename
    aLink.click()
    aLink.remove()
  }

  // TODO: make these into context !!!
  const onSelectOption = (x) => {
    onSavedSearchSelected(x)
    setOpenLibrary(false)
  }

  return (
    <>
      <Modal isOpen={openLibrary} setOpen={setOpenLibrary}>
        <MyLibrary toggleModal={closeLibraryModal} onSelect={onSelectOption} />
      </Modal>

      <div className="flex justify-between">
        <button
          onClick={() => {
            navigate(-1)
          }}
          className="flex items-center gap-[1.5px] font-500 text-15 leading-[18.9px] text-gray-400"
        >
          <Icon name="BackArrow" />
          Back To Project
        </button>
      </div>
    </>
  )
}
