import { useState } from "react"

import { Icon } from "@/assets"
import ProfileModal from "./profileModal"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { logout } from "@/redux/slices/auth"
import { useAppDispatch } from "@/redux/hooks"
import { useGetUserQuery, userApi } from "@/api/resources"
import Avatarr from "./Avatar"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"

export default function ProfileSettings() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { data: user } = useGetUserQuery()
  const [openProfileSettings, setOpenProfileSettings] = useState<boolean>(false)

  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const doLogout = () => {
    dispatch(logout())
    dispatch(userApi.util.resetApiState())

    toast.success("Logged out successfully")
    navigate("/login")
  }

  return (
    <>
      <ProfileModal
        isOpen={openProfileSettings}
        setOpen={setOpenProfileSettings}
      />
      <div className="relative">
        <DropdownMenu open={isDropdownOpen} onOpenChange={setDropdownOpen}>
          <DropdownMenuTrigger asChild>
            <button className="flex gap-4 items-center px-1 rounded-5 hover:bg-gray-100">
              <span className="text-center text-slate-700 font-medium text-sm whitespace-nowrap">{user?.first_name} {user?.last_name}</span>
              <Avatar className="w-8 h-8">
                  <AvatarImage src={user?.photo ?? ""} />
                  <AvatarFallback>
                    {user?.first_name?.charAt(0)}
                  </AvatarFallback>
                </Avatar>
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-40" align="end">
            <DropdownMenuItem
              className="py-3"
              onClick={() => setOpenProfileSettings(true)}
            >
              <Icon name="IProfile" className="mr-2" /> Profile Settings
            </DropdownMenuItem>
            <DropdownMenuItem className="py-3" onClick={doLogout}>
              <Icon name="Logout" className="mr-2" /> Log Out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  )
}
