import { Icon } from "@/assets"
import SearchMatch from "../main/searchMatch"
import Keywords from "@/components/keywords"
import { useDocumentContext } from "../DocumentContext"


export function KeywordSearchResults() {
  const { keywords, setKeywords } =
    useDocumentContext()

  const deleteKeyword = (idx: number) => {
    const existingKeywords = [...keywords]
    existingKeywords.splice(idx, 1)
    setKeywords(existingKeywords)
  }

  return (
    <div>
      <SearchMatch>
        <button
          onClick={() => {
            setKeywords([])
          }}
          className="px-3 py-2 mt-3 mb-3 flex center
            gap-2 bg-bgSecondary rounded-5
            text-12"
        >
          {/* TODO: replace with lucide icon */}
          <Icon name="Close" />
          Clear search
        </button>
        <Keywords
          keywords={keywords}
          parentClass={"pb-6 flex-wrap"}
          childClass={
            "rounded-6 bg-background-darkGrey text-text-darkGrey font-500 text-16 leading-4 flex gap-2 items-center w-fit p-2"
          }
          deleteKeyword={deleteKeyword}
        />
      </SearchMatch>
    </div>
  )
}
