"use client"

import { useEffect, useMemo, useState } from "react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { ChevronDown, X, Filter, ChevronRight, ChevronUp } from "lucide-react"
import { useDeleteDatasetMutation, useGetDatasetQuery, useLaunchTrainingMutation, useUpdateDatasetExampleMutation } from "@/api/resources"
import { Switch } from "@/components/ui/switch"
import { useNavigate, useParams } from "react-router-dom"
import { ModelsDrawer } from "./ModelsList"
import { toast } from "sonner"
import DataTable from "./data-table"

export default function DataLabelingInterface() {
  const { id: datasetId } = useParams()
  const navigate = useNavigate()

  const { data: dataset, isLoading: isDatasetLoading } = useGetDatasetQuery(datasetId || "", { skip: !datasetId })
  const [updateDatasetExample] = useUpdateDatasetExampleMutation()
  const [deleteDataset] = useDeleteDatasetMutation()
  
  const [showModal, setShowModal] = useState(true)
  const [selectedGiven, setSelectedGiven] = useState("All")
  const [selectedSuggested, setSelectedSuggested] = useState("All")
  const [selectedIssues, setSelectedIssues] = useState("All")
  const [expandedRows, setExpandedRows] = useState<number[]>([])

  const [selectedTab, setSelectedTab] = useState("")

  const data = useMemo(() => {
    if (!dataset) return null
    return dataset.data[selectedTab]
  }, [dataset, selectedTab])

  useEffect(() => {
    setExpandedRows([])
    if (dataset?.data && !selectedTab) {
      setSelectedTab(Object.keys(dataset?.data)[0])
    }
  }, [dataset])
  
  const toggleRowExpansion = (row: number) => {
    setExpandedRows((prev) => (prev.includes(row) ? prev.filter((r) => r !== row) : [...prev, row]))
  }

  const handleUserLabelChange = (row: number, checked: boolean) => {
    if (!dataset) return
    updateDatasetExample({ dataset_id: dataset.id, row_idx: row, label: checked ? 1 : 0 })
  }

  const datasetLength = data?.text?.length || null
  // nWellLabeled is number of examples where user_label is the same as cv_label
  let nWellLabeled = "-"
  if (data?.user_label && data?.cv_label) {
    nWellLabeled = data.user_label.filter((label: number, idx: number) => label === data.cv_label[idx]).length
  }
  let nIssuesResolved = "-"
  if (data?.user_label && data?.orig_label) {
    nIssuesResolved = data.user_label.filter((label: number, idx: number) => label !== data.orig_label[idx]).length
  }

  const sidebarStats = [
    { label: "Total Examples", value: datasetLength },
    { label: "Well Labeled", value: nWellLabeled },
    { label: "Issues Resolved", value: nIssuesResolved },
    { label: "Label Issues", value: "23" },
    { label: "Outliers", value: "11" },
    { label: "Unlabeled", value: "10" },
    { label: "Ambiguous", value: "16" },
    { label: "Near Duplicates", value: "6" },
    { label: "Text-specific analyses", value: "" },
    { label: "PII", value: "5" },
    { label: "Informal", value: "53" },
    { label: "Non English", value: "4" },
    { label: "Toxic", value: "3" },
  ]

  const onDeleteDataset = async () => {
    // TODO: add confirmation modal
    if (dataset?.id) {
      const response = await deleteDataset(dataset.id)
      if (response.error) {
        toast.error("Failed to delete dataset")
      } else {
        toast.success("Dataset deleted successfully")
        navigate("/datasets")
      }
    }
  }

  console.log(dataset)
  console.log("DATA:")
  console.log(data)

  if (isDatasetLoading) {
    return <div className="flex h-screen bg-background">
      <div className="flex flex-col h-full w-full items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-black border-t-transparent"></div>
        <div className="ml-2 text-sm font-medium mt-8">Downloading dataset...</div>
      </div>
    </div>
  }

  return (
    <div className="flex h-screen bg-background">
      {/* Sidebar */}
      <div className="w-64 border-r bg-background p-4">
        <div className="mb-4">
          <Button variant="ghost" className="w-full justify-between">
            <span>FILTER</span>
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
        <div className="space-y-1">
          <Button variant="ghost" className="w-full justify-between">
            <span>Sub-datasets</span>
            {/* <ChevronRight className="h-4 w-4" /> */}
          </Button>
          <div className="space-y-1 pl-4">
            {
              dataset && Object.keys(dataset.data).map((split) => (
                <Button variant="ghost" className="w-full justify-between" onClick={() => setSelectedTab(split)}>
                <span>{split}</span>
                <ChevronRight className="h-4 w-4" />
              </Button>
              ))
            }
          </div>
          {/* <Button variant="ghost" className="w-full justify-between">
            <span>Presets</span>
            <ChevronDown className="h-4 w-4" />
          </Button> */}
        </div>
        <div className="mt-4 space-y-2">
          {sidebarStats.map((stat) => (
            <div key={stat.label} className="flex justify-between text-sm">
              <span className="text-muted-foreground">{stat.label}</span>
              <span className="font-medium">{stat.value}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1">
        <div className="p-4">
          <div className="mb-4">
            <h1 className="text-xl">Annotation for <span className="font-semibold">{dataset?.name}</span></h1>
          </div>

          {/* Filters */}
          <div className="mb-4 flex items-center gap-2">
            <Button variant="ghost" size="sm" className="text-muted-foreground">
              <X className="mr-1 h-4 w-4" />
              Clear
            </Button>
            <Select value={selectedGiven} onValueChange={setSelectedGiven}>
              <SelectTrigger className="w-[150px]">
                <span className="text-muted-foreground">Given: </span>
                <SelectValue placeholder="All" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="All">All</SelectItem>
              </SelectContent>
            </Select>
            <Select value={selectedSuggested} onValueChange={setSelectedSuggested}>
              <SelectTrigger className="w-[150px]">
                <span className="text-muted-foreground">Suggested: </span>
                <SelectValue placeholder="All" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="All">All</SelectItem>
              </SelectContent>
            </Select>
            <Select value={selectedIssues} onValueChange={setSelectedIssues}>
              <SelectTrigger className="w-[150px]">
                <span className="text-muted-foreground">Issues: </span>
                <SelectValue placeholder="All" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="All">All</SelectItem>
              </SelectContent>
            </Select>
            <Button variant="outline" size="sm">
              <Filter className="mr-1 h-4 w-4" />
              New Filter
            </Button>
            <ModelsDrawer />
            <Button variant="destructive" size="sm" onClick={onDeleteDataset}>Delete Dataset</Button>
          </div>

          {/* Table */}
          <div className="rounded-md border">
            {data ? <DataTable data={data} onLabelChange={handleUserLabelChange} /> : <div>Data is loading or no data found</div>}
          </div>

          {/* Footer */}
          {/* <div className="mt-4 flex items-center justify-between">
            <div className="flex gap-2">
              <Button variant="default">Export Cleanset</Button>
              <Button variant="outline">Clean Top K</Button>
              <Button variant="outline">Improve Results</Button>
            </div>
            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              <span>1 to 50 of 1,000</span>
              <div className="flex gap-1">
                <Button variant="outline" size="icon" className="h-8 w-8">
                  <ChevronRight className="h-4 w-4 rotate-180" />
                </Button>
                <Button variant="outline" size="icon" className="h-8 w-8">
                  <ChevronRight className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
