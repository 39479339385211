"use client"

import { useState } from "react"
import { Plus } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { DatasetSchema } from "@/api/resources/types"
import { useGetDatasetsQuery } from "@/api/resources"
import { Link, useNavigate } from "react-router-dom"

export default function DatasetList() {
  const navigate = useNavigate()
  const { data: datasets, isLoading } = useGetDatasetsQuery()

  if (isLoading) return <div>Loading...</div>

  return (
    <div className="container mx-auto py-10">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Datasets</h1>
        <Link to="/datasets/create">
          <Button>
            <Plus className="mr-2 h-4 w-4" /> Create New
          </Button>
        </Link>
      </div>
      <p className="font-medium">A dataset is a collection of example clauses that you want to flag in your documents.</p>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Created At</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {datasets?.map((dataset) => (
            <TableRow key={dataset.id} onClick={() => navigate(`/datasets/${dataset.id}`)} className="cursor-pointer">
              <TableCell className="font-medium">{dataset.name}</TableCell>
              <TableCell>{dataset.description}</TableCell>
              <TableCell>{new Date(dataset.created_at).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
