import { useView } from "@/context/ViewContext"
import { useEffect, useState } from "react"
import Search from "./searchBar/search"
import SimilarityCriteria from "../EditTab"
import { SavedSearchSchema } from "@/api/resources/types"
import { handlePasteFunction } from "@/components/left/main/searchBar/search"
import { useDocumentContext } from "@/components/left/DocumentContext"
import { DocumentNavigationMenu } from "@/components/left/main/NavigationMenu"
import Library from "@/components/left/main/searchBar/library";
import OverviewTab from "../OverviewTab"

export default function Main({
  keywords,
  setKeywords,
}) {
  const [query, setQuery] = useState<string>("")
  const { splitView, toggleView } = useView()

  const {
    semanticSearchClass: chosenCriteria,
    setSemanticSearchClass: setChosenCriteria,
    tab, setTab
  } = useDocumentContext()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && query.trim().length > 0) {
      e.preventDefault()
      console.log("Searching for:", query)

      setKeywords([...keywords, query])
      setQuery("")
    }
  }

  const onSelect = (savedSearch: SavedSearchSchema) => {
    savedSearch ? setKeywords(savedSearch.keywords) : setKeywords([])
  }

  const handlePaste = handlePasteFunction(keywords, setKeywords)

  if (splitView) {
    return <>Split view not supported</>
  }

  useEffect(() => {
    if (tab === "edit" && !chosenCriteria) {
      setTab("overview")
    }
  }, [chosenCriteria, tab])

  return (
    <div className="pt-3">
      <DocumentNavigationMenu tab={tab} setTab={setTab} />
      {
        tab === "overview" && (
          <div>
            <Search
              query={query}
              handleChange={handleChange}
              handleKeyPress={handleKeyPress}
              handlePaste={handlePaste}
              placeholder="Type keywords ⏎, or paste from excel"
              classname="pt-3 pb-2"
            />
            <div className="flex justify-end w-full">
              <Library onSelect={onSelect} />
            </div>
          </div>
        )
      }
      {tab === "overview" && <OverviewTab />}
      {tab === "edit" && chosenCriteria && (
        <SimilarityCriteria />
      )}
    </div>
  )
}
