import { useAssignModelToClassMutation, useCancelTrainingMutation, useDeleteModelMutation, useGetClassesQuery, useGetDatasetModelsQuery, useLaunchTrainingMutation } from "@/api/resources"
import { useParams } from "react-router-dom"
import { Drawer, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger, DrawerClose } from "@/components/ui/drawer"
// import {
//   Dialog,
//   DialogContent,
//   DialogDescription,
//   DialogHeader,
//   DialogTitle,
//   DialogTrigger,
// } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { TrainedModelSchema } from "@/api/resources/types"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Check, ChevronsUpDown, Loader2, SidebarClose, Trash, X } from "lucide-react"
import { useCallback, useState } from "react"
import { Command, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command"
import { CommandEmpty } from "@/components/ui/command"
import { CommandList } from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { cn } from "@/lib/utils"

export function ModelsList({ data }: { data: TrainedModelSchema[] }) {
  return <div className="max-w-xl overflow-x-wrap  overflow-y-auto">
    {data?.map((model) => (
      <ModelCard key={model.id} model={model} />
    ))}
  </div>
}


const ClassModelSelection = ({ model }: { model: TrainedModelSchema }) => {
  const { data: classes, isLoading: classesLoading } = useGetClassesQuery()
  const [assignModelToClass] = useAssignModelToClassMutation()
  const handleClassSelect = (classId: string, modelId: string) => {
    assignModelToClass({ class_id: classId, model_id: modelId })
  }

  return (
    <Popover modal>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" className="w-64 justify-between">
          {model.class_id
            ? classes?.find(cls => cls.id === model.class_id)?.name
            : "Select class..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-64 p-0">
        <Command>
          <CommandInput placeholder="Search classes..." />
          <CommandList>
            <CommandEmpty>{classesLoading ? "Classes are loading ..." : "No classes found."}</CommandEmpty>
            <CommandGroup>
              {classes?.map((cls) => (
                <CommandItem
                  key={cls.id}
                  onSelect={() => handleClassSelect(cls.id, model.id)}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      model.class_id === cls.id ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {cls.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}


const ModelCard = ({ model }: { model: TrainedModelSchema }) => {
  const [cancelTraining] = useCancelTrainingMutation()
  // const [isCancelling, setIsCancelling] = useState(false)
  // const [assignModelToClass] = useAssignModelToClassMutation()
  const [deleteModel] = useDeleteModelMutation()

  const metrics = {
    "Sensitivity": model.metrics.sensitivity?.toFixed(3),
    "Specificity": model.metrics.specificity?.toFixed(3),
    "Examples": model.metrics?.n_pos,
    "Counter-examples": model.metrics?.n_neg,
  }
  return (
    <Card key={model.id} className="shadow-none rounded-none border-gray-200">
      <CardHeader>
        <CardTitle>{model.name}</CardTitle>
        <CardDescription>Version: {model.version} | Created at: {new Date(model.created_at).toLocaleString()}</CardDescription>
      </CardHeader>
      <CardContent>
        { model.status === "training" ? (
          <div className="flex gap-4 justify-center items-center h-24">
            <Loader2 className="w-6 h-6 animate-spin" />
            <Button variant="destructive" size="sm" onClick={() => {
              cancelTraining({ model_id: model.id })
              // setIsCancelling(true)
            }}>Cancel Training</Button>
          </div>
        ) : (
          <>
          <table>
            <tbody>
            {Object.entries(metrics).map(([key, value]) => (
              <tr key={key} className="flex justify-between ">
                <td className="text-sm">{key}</td>
                <td className="text-sm font-semibold pl-10">{value}</td>
              </tr>
            ))}
            </tbody>
          </table>
          <div className="flex justify-end">
            <Button variant="ghost" size="sm" onClick={() => {
              deleteModel(model.id)
            }}>
              <Trash className="w-4 h-4" />
            </Button>
          </div>
          {/* <ClassModelSelection model={model} /> */}
          </>
        )}
      </CardContent>
    </Card>
  )
}

export const ModelsDrawer = () => {
  const { id: datasetId } = useParams()
  const [launchTraining] = useLaunchTrainingMutation()
  const { data, isLoading } = useGetDatasetModelsQuery(datasetId ?? "", { skip: !datasetId, pollingInterval: 5000, skipPollingIfUnfocused: true })
  const isTrainingInProgress = data?.some(model => model.status === "training")

  const startTraining = useCallback(() => datasetId ? launchTraining(datasetId) : null, [datasetId])
  
  return (
    <Drawer direction="left">
      <DrawerTrigger asChild>
        <Button variant="outline" size="sm">
        Model training
        </Button>
      </DrawerTrigger>
      <DrawerContent className="max-w-xl h-screen">
        <DrawerHeader>
          <div className="flex justify-between items-center">
            <DrawerTitle>Model training</DrawerTitle>
            <DrawerClose>
              <X className="w-5 h-5 text-gray-500" />
            </DrawerClose>
          </div>
          <DrawerDescription>Here you can see all your models trained using this dataset.</DrawerDescription>
        </DrawerHeader>
        {
          isLoading ? (
            <div className="flex justify-center items-center h-24">
              <Loader2 className="w-6 h-6 animate-spin" />
            </div>
          ) : (
            <ModelsList data={data ?? []} />
          )
        }
        <div className="flex justify-start ml-4">
          <Button onClick={startTraining} disabled={isLoading || isTrainingInProgress}>Run training</Button>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

// export const ModelsModal = () => {
//   return (
//     <Dialog>
//       <DialogTrigger>Open</DialogTrigger>
//       <DialogContent>
//         <DialogHeader>
//           <DialogTitle>Trained Models</DialogTitle>
//           <DialogDescription>
//             Here you can see all your models trained using this dataset.
//           </DialogDescription>
//         </DialogHeader>
//         <ModelsList />
//       </DialogContent>
//     </Dialog>
//   )
// }