import * as React from "react"
import { Check, ChevronsUpDown, PlusIcon } from "lucide-react"
import unidecode from "unidecode";

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { DialogTitle, DialogContent, DialogHeader, DialogTrigger } from "@/components/ui/dialog"
import { Dialog } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { useGetProjectPartiesQuery } from "@/api/resources"
import { PartySchema } from "@/api/resources/types"



export function PartyPickerCombobox({ parties, onSelect, allowAddNewParty = true }: { parties: PartySchema[], onSelect: (party: string) => void, allowAddNewParty?: boolean }) {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState("")
  const [search, setSearch] = React.useState("")

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {value
            ? value
            : "Add party..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[350px] p-0">
        <Command
            filter={(value, search) => {
            // Convert both value and search to lowercase for case-insensitive comparison
            const itemValue = unidecode(value).toLowerCase();
            const searchTerm = unidecode(search).toLowerCase();

            // Define custom matching logic
            if (itemValue.includes(searchTerm)) {
                return 1; // Full match
            }
            if (value === "add-new-party") {
                return 1;
            }
            return 0; // No match
        }}>
          <CommandInput placeholder="Search party..." onValueChange={(value: any) => setSearch(value)} />
          <CommandList>
            <CommandEmpty>No parties found.</CommandEmpty>
            <CommandGroup>
              {parties.map((party) => (
                <CommandItem
                  key={party.name}
                  value={party.name}
                  onSelect={(currentValue) => {
                    setValue("")
                    setOpen(false)
                    onSelect(currentValue)
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === party.name ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {party.name}
                </CommandItem>
              ))}
              {allowAddNewParty && <CommandItem key="add-new-party" value="add-new-party" onSelect={() => {
                    if (search) {
                        setValue("")
                        setOpen(false)
                        onSelect(search)
                    }
                }}>
                    <PlusIcon className="w-4 h-4" />
                    Add new party
                </CommandItem>}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
