import { useEffect, useState } from "react"

export default function TypingIndicator() {
  const [dots, setDots] = useState(1)

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev % 3) + 1)
    }, 200)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="flex h-10 w-20 place-self-end items-center justify-center rounded-xl bg-gray-100 px-4 p-2 mt-2 ml-[5%]">
    <div className="flex space-x-1">
        <div className={`h-2 w-2 rounded-full bg-gray-500 ${dots >= 1 ? "opacity-100" : "opacity-30"}`}></div>
        <div className={`h-2 w-2 rounded-full bg-gray-500 ${dots >= 2 ? "opacity-100" : "opacity-30"}`}></div>
        <div className={`h-2 w-2 rounded-full bg-gray-500 ${dots >= 3 ? "opacity-100" : "opacity-30"}`}></div>
    </div>
    </div>
  )
}
