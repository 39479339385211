import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"
import DatasetsList from "./DatasetsList"
import ClassesList from "../classes/ClassesList"

export default function TabbedLayout() {
  return (
    <Tabs defaultValue="datasets" className="w-full max-w-[1280px] mx-auto">
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value="datasets">Datasets</TabsTrigger>
        <TabsTrigger value="classes">Classes</TabsTrigger>
      </TabsList>
      <TabsContent value="datasets">
        <DatasetsList />
      </TabsContent>
      <TabsContent value="classes">
        <ClassesList />
      </TabsContent>
    </Tabs>
  )
}
