import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Icon } from "@/assets"
import { CreateOrRenameFolderDialog } from "@/components/Project/project/CreateOrRenameFolderDialog"
import { ResourceSchema, useReanalyzeDocumentsMutation } from "@/api/resources"
import { useState } from "react"
import { DeleteFolderDialog } from "@/components/Project/project/DeleteFolderDialog"
import ReanalyzeModal from "../reanalyze-modal"
import { Edit, FolderPen, FolderSync, Trash } from "lucide-react"

interface Props {
  folder: ResourceSchema
}

export default function FolderDropdown({ folder }: Props) {

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isRenameModal, setRenameModal] = useState(false)
  const [isDeleteModal, setDeleteModal] = useState(false)
  const [isReanalyzeModal, setReanalyzeModal] = useState(false)

  const [ reanalyzeDocuments ] = useReanalyzeDocumentsMutation()

  return (
    <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen} modal>
      <DropdownMenuTrigger>
        <Icon name="More" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="py-2">
        <DropdownMenuItem
          className="mb-1"
          onClick={() => {
            setIsDropdownOpen(false)
            setReanalyzeModal(true)
          }}
        >
          <FolderSync className="h-4 mr-1" />
          Reanalyze
        </DropdownMenuItem>
        <DropdownMenuItem
          className="mb-1"
          onClick={() => {
            setIsDropdownOpen(false)
            setRenameModal(true)
          }}
        >
          <FolderPen className="h-4 mr-1" />
          Rename
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            setIsDropdownOpen(false)
            setDeleteModal(true)
          }}
        >
          <Trash className="h-4 mr-1" />
          Delete
        </DropdownMenuItem>
      </DropdownMenuContent>
      {/* this is not very efficient*/}
      <CreateOrRenameFolderDialog
        projectId={folder.project_id}
        folderId={folder.directory_id}
        initialName={folder.display_name}
        action="rename"
        open={isRenameModal}
        onOpenChange={setRenameModal}
      />
      <DeleteFolderDialog
        directoryId={folder.directory_id}
        open={isDeleteModal}
        onOpenChange={setDeleteModal}
      />
      <ReanalyzeModal
        folderId={folder.directory_id ?? undefined}
        open={isReanalyzeModal}
        setOpen={(x) => setReanalyzeModal(x)}
        onSubmit={(run_ocr, run_class_identification) => {
          if (!folder.project_id || !folder.directory_id) {
            return
          }
          reanalyzeDocuments({ 
            project_id: folder.project_id, 
            base_resource_id: folder.directory_id, 
            run_ocr, 
            run_class_identification 
          })
        }}
      />
    </DropdownMenu>
  )
}
