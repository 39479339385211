import { Icon } from "@/assets"
import CriteriaMatch from "./CriteriaMatch"
import CriteriaDropdown from "./CriteriaDropdown"
import { useDocumentContext } from "../DocumentContext"

export default function SimilarityCriteria() {
  const { openDefaultTab, semanticSearchClass, predictionsInfo } = useDocumentContext()

  const selectedClass = semanticSearchClass ? predictionsInfo.find(x => x.class_id === semanticSearchClass) : null

  return (
    <div className="mt-4">
      <div className="flex items-center mb-3 gap-5">
        <button
          onClick={() => openDefaultTab()}
          className="px-3 py-2.5 flex center
            gap-2 bg-bgSecondary rounded-5
            text-12"
        >
          <Icon name="Close" />
          Clear search
        </button>
        <div className="flex flex-col gap-0">
          <h3 className="font-500">{selectedClass?.class_name}</h3>
          <span className="text-12">
          {
            selectedClass?.labeled_by ? (
              <>
                Last updated by: {selectedClass?.labeled_by.first_name} {selectedClass?.labeled_by.last_name}
              </>
            ) : (
              "Not annotated by human yet"
            )
            }
          </span>
        </div>
        {/* <CriteriaDropdown /> */}
      </div>
      <CriteriaMatch />
    </div>
  )
}
