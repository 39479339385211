import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react"
import { useLocation, useParams } from "react-router"
import { Link, useSearchParams } from "react-router-dom"
import axios from "axios"

import { API_URL } from "@/config"
import { useAppSelector } from "@/redux/hooks"
import { Left, Right } from "@/components"
import { htmlFile as fakeHtmlFile } from "@/mock/data/tempfile"
import DocumentContext, {
  SemanticMatch,
} from "@/components/left/DocumentContext"
import {
  ParsedDocumentSchema,
  ProvisionKeys,
  useGetClassesQuery,
  useGetDocumentMetadataQuery,
  useGetDocumentPredictionsForDisplayQuery,
  useGetDocumentPredictionsInfoQuery,
  useGetParsedDocumentQuery,
  useSearchDocumentKeywordsQuery,
  useUpdateDocumentAnnotationsMutation
} from "@/api/resources";
import AppPage from "@/AppPage"
import { Icon } from "@/assets"
import {
  useParser,
} from "@/components/right/readOnlyEditor/DocumentParser"
import { toast } from "sonner";
import { ddCriteria, DdCriterion } from "@/data/criteria"
import { Loader2 } from "lucide-react"
import { DocumentPredictionsInfoSchema } from "@/api/resources/types"

const PROBA_THRESHOLD = 0.05

function isSameBin(a, b) {
  if (a >= 0.6 && b >= 0.6) return true
  if (a >= 0.25 && a < 0.6 && b >= 0.25 && b < 0.6) return true
  if (a < 0.25 && b < 0.25) return true
  return false
}

function sortFn(a, b) {
  // originally: (a, b) => b.value - a.value
  if (isSameBin(b.value, a.value)) {
    return a.index - b.index
  } else {
    return b.value - a.value
  }
}

function useHTMLProvider({
  id,
  accessToken,
  documentMetadata,
  semanticSearchData,
  semanticSearchClass,
  keywords,
  activeMatch,
}: {
  id: string
  accessToken: string
  documentMetadata: any
  semanticSearchData: any
  semanticSearchClass: string|null
  keywords: string[]
  activeMatch: number | null
}) {
  const [htmlFile, setHtmlFile] = useState<string | null>(null)
  const { searchableElements } = useParser({ htmlFile, keywords, activeMatch })

  useEffect(() => {
    if (id !== undefined) {
      // setHtmlFile()
      const options = {
        headers: {
          Accept: "text/html",
          Authorization: `Bearer ${accessToken}`,
        },
      }
      axios
        .get(`${API_URL}/documents/${id}`, options)
        .then((resp) => {
          if (resp.status == 200) {
            setHtmlFile(resp.data)
          } else {
            setHtmlFile(null)
          }
        })
        .catch((err) => {
          // TODO
          // setHtmlFile(fakeHtmlFile)
          throw err
        })
    } else {
      setHtmlFile(fakeHtmlFile)
    }
  }, [id])

  const semanticMatchesHtml: SemanticMatch[] = useMemo(() => {
    if (
      !semanticSearchData ||
      !searchableElements ||
      !semanticSearchClass ||
      documentMetadata?.file_type === "pdf"
    ) {
      return []
    }
    const data = semanticSearchData["predictions"][semanticSearchClass]
    if (data.length !== searchableElements.length) {
      console.log(
        `ERROR: data.length != searchableElements.length (${data.length}, ${searchableElements.length})`,
      )
      return []
    }
    const matches_ = []
    for (let i = 0; i < data.length; i++) {
      if (data[i] < PROBA_THRESHOLD) {
        continue
      }
      const elem = searchableElements[i]
      matches_.push({
        index: elem.idx,
        matchIndex: i,
        text: elem.text,
        value: data[i],
      })
    }
    return matches_.sort(sortFn)
  }, [semanticSearchData, searchableElements, semanticSearchClass])

  return {
    htmlFile,
    keywordMatches: [], // TODO
    semanticMatches: semanticMatchesHtml,
  }

}


function useKeywordMatches({
  documentMetadata,
  parsedDocument,
  keywords,
}: {
  documentMetadata: any
  parsedDocument: ParsedDocumentSchema | null
  keywords: string[]
}) {

  const skipCall = !documentMetadata.document_id || !keywords || keywords.length === 0
  const { data: keywordMatches, isLoading: keywordMatchesLoading } = useSearchDocumentKeywordsQuery({
    document_id: documentMetadata.document_id,
    keywords: keywords,
    // TODO: what are keywordsPrepro?
  }, { skip: skipCall })
  // list of {id, keywords, content}

  // TODO: refactor the place where this is used and remove this reformatting
  // TODO: somehow this fails downstream
  const keywordMatchesInNiceFormat = useMemo(() => {
    if (keywordMatchesLoading || !keywordMatches) return []
    return keywordMatches.map((x: any, i: number) => ({
      index: Number(x.id.split('#')[1]),
      matchIndex: i,
      text: x.content,
      value: -1, // TODO: remove this
      // keywords: x.keywords,
    })).sort((a: any, b: any) => a.index - b.index)
  }, [keywordMatches, keywordMatchesLoading])

  return {
    keywordMatches: skipCall ? [] :keywordMatchesInNiceFormat,
  }
}


export default function DocumentRoot() {
  const { id } = useParams()
  const accessToken = useAppSelector((state) => state.auth.accessToken)
  const [searchParams] = useSearchParams()
  const { hash } = useLocation()
  const { data: documentMetadata, isLoading } = useGetDocumentMetadataQuery(id ?? "", { skip: !id })
  const { data: parsedDocument, isLoading: textChunksLoading } = useGetParsedDocumentQuery(id ?? "", { skip: !id })

  const [tab, setTab] = useState<"overview"|"edit">(searchParams.get("provision") ? "edit": "overview")

  const preselectedMatch = isNaN(parseInt(hash.slice(1))) ? null : parseInt(hash.slice(1))

  if (isLoading || textChunksLoading) {
    return <div className="flex flex-grow justify-center items-center h-full">
      <Loader2 className="w-10 h-10 animate-spin" />
    </div>
  }

  if (!id || !documentMetadata) {
    return (
      <AppPage>
        <div className="flex flex-col flex-grow gap-5 justify-center items-center">
          <span className="text-20">Document not found.</span>
          <Link
            to="/"
            className="flex items-center gap-2 font-500 text-15 text-gray-400 hover:text-gray-700"
          >
            <Icon name="BackArrow" />
            Back To Main Page
          </Link>
        </div>
      </AppPage>
    )
  }

  if (!accessToken) {
    // TODO: redirect to login
    return <div>You are not logged in!</div>
  }

  // // scroll to top of page after a page transition.
  // useLayoutEffect(() => {
  //   document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" })
  // }, [location.pathname])

  return <Page 
    id={id} 
    accessToken={accessToken} 
    preselectedMatch={preselectedMatch} 
    documentMetadata={documentMetadata}
    parsedDocument={parsedDocument}
    tab={tab}
    setTab={setTab}
  />

}


const useEditTabState = ({ documentId, parsedDocument }: { documentId: string, parsedDocument: ParsedDocumentSchema | null }) => {
  const [searchParams] = useSearchParams()
  const [updateLabels] = useUpdateDocumentAnnotationsMutation()

  const [semanticSearchClass, setSemanticSearchClass] = useState<string | null>(
    searchParams.get("provision"),
  )
  const { data: predictions, isLoading: predictionsLoading } = useGetDocumentPredictionsForDisplayQuery(
    { document_id: documentId, class_ids: [semanticSearchClass ?? ""] },
    { skip: !documentId || !semanticSearchClass },
  )

  const shownPredictions = useMemo(() => {
    if (predictionsLoading || !predictions || !semanticSearchClass) return null
    return predictions[semanticSearchClass]
  }, [predictions, predictionsLoading, semanticSearchClass])

  const allMatches = useMemo(() => {
    return shownPredictions &&
      parsedDocument?.text_chunks
      ? parsedDocument?.text_chunks?.map((x, i) => ({
          index: i,
          matchIndex: i,
          text: x.text,
          value:
            shownPredictions.predictions[i],
          isUserValidated:
            shownPredictions.user_labels ? shownPredictions.user_labels[i] : shownPredictions.predictions[i],
        }))
      : []
  }, [shownPredictions, parsedDocument])

  // local copy of allMatches with user changes
  const [bufferMatches, setBufferMatches] = useState<typeof allMatches>(
    [],
  )

  // FIXME: handle edge cases such as reloading
  useEffect(() => {
    // We need to keep track of allMatches changes, so we can't just pass this
    // as a parameter to the useState
    setBufferMatches(allMatches.map((match) => ({ ...match })))

    if (semanticSearchClass && !predictionsLoading && !predictions) {
      // Not sure if we need to keep this; however, for presentation purposes I'll put it up here.
      toast.error(
        `Semantic matches not yet processed for "${semanticSearchClass.replaceAll("_", " ").toWellFormed()}."`,
        {
          description:
            "Please wait for the semantic search to finish processing.",
        },
      )
    }
  }, [allMatches])

  const setMatchUserLabel = useCallback(
    (matchIndex: number, binaryLabel: boolean) => {
      const newBufferMatches = bufferMatches.slice()
      newBufferMatches[matchIndex].isUserValidated = binaryLabel ? 1 : 0
      setBufferMatches(newBufferMatches)
    },
    [bufferMatches, setBufferMatches],
  )

  const areMatchesModified =
    allMatches.length !== 0 &&
    bufferMatches.some(
      (match) =>
        match.isUserValidated !== allMatches[match.index].isUserValidated,
    )

  const persistUserLabels = async () => {
    const toastLoadingid = toast.loading("Updating labels...")

    const result = await updateLabels({
      document_id: documentId,
      target_class: semanticSearchClass ?? "",
      changed_labels: bufferMatches.map((match, i) => match.isUserValidated)
    })

    if (result.error) {
      toast.error("Error updating labels", {
        description: (result.error as any).data.detail,
        id: toastLoadingid,
      })
    } else {
      toast.success("Labels were successfully updated.", {
        id: toastLoadingid,
      })
    }
  }

  return {
    bufferMatches,
    setMatchUserLabel,
    areMatchesModified,
    persistUserLabels,
    editingClass: semanticSearchClass,
    setEditingClass: setSemanticSearchClass,
  }

}


export function Page({
  id,
  accessToken,
  preselectedMatch,
  documentMetadata,
  parsedDocument,
  tab,
  setTab,
}: {
  id: string
  accessToken: string
  preselectedMatch: number | null
  documentMetadata: any
  parsedDocument: ParsedDocumentSchema | null
  tab: "overview" | "edit"
  setTab: (tab: "overview" | "edit") => void
}) {
  const [searchParams] = useSearchParams()

  const [keywords, setKeywords] = useState<string[]>([])
  const [activeMatch, setActiveMatch] = useState<number | null>(
    preselectedMatch,
  )

  // overview tab
  const [selectedClasses, setSelectedClasses] =
    useState<DocumentPredictionsInfoSchema[]>([])

  const { data: predictionsInfo, isLoading: predictionsInfoLoading } = useGetDocumentPredictionsInfoQuery(id ?? "", { skip: !id })

  // const { htmlFile, semanticMatchesHtml } = useHTMLProvider({
  //   id,
  //   accessToken,
  //   documentMetadata,
  //   semanticSearchData,
  //   semanticSearchClass,
  //   keywords,
  //   setKeywords,
  //   setMatches,
  //   activeMatch,
  //   setActiveMatch,
  // })

  const {
    bufferMatches,
    setMatchUserLabel,
    areMatchesModified,
    persistUserLabels,
    editingClass,
    setEditingClass,
  } = useEditTabState({ documentId: id, parsedDocument })

  const { keywordMatches } = useKeywordMatches({
    documentMetadata,
    parsedDocument,
    keywords,
  })

  const setKeywordsAndClearMatches = useCallback(
    (newKeywords: string[]) => {
      // Called when keywords are updated to also clear matches
      setActiveMatch(null)
      setKeywords(newKeywords)
    },
    [setKeywords, setActiveMatch],
  )

  const openDefaultTab = useCallback(() => {
    if (tab !== "overview") {
      // setSemanticSearchClass(null)  // we don't want that, do we?
      setTab("overview")
    }
  }, [tab, setTab])

  const openEditTab = useCallback(
    (searchClass: string) => {
      if (tab !== "edit" || editingClass !== searchClass) {
        setEditingClass(searchClass)
        setTab("edit")
      }
    },
    [tab, setTab, editingClass, setEditingClass],
  )

  /*
  selectedClasses: DocumentPredictionsInfoSchema[] used in overview tab
  bufferMatches: SemanticMatch[] with user changes used in edit tab
  predictionsInfo: list of available classes and their model names
  */

  return (
    <DocumentContext.Provider
      value={{
        documentId: id,
        document: documentMetadata,
        htmlFile: null, // TODO
        semanticMatches: null, // UNUSED - only used in old HTML viewer
        semanticSearchClass: editingClass, // TODO: rename to editingClass
        setSemanticSearchClass: setEditingClass,  // TODO: rename
        activeMatch,
        setActiveMatch,
        allMatches: bufferMatches,
        setMatchUserLabel,
        areMatchesModified,
        persistUserLabels,
        keywordMatches: // TODO: investigate how this works
          tab === "overview"
            ? keywordMatches
            : [],
        //
        keywords,
        setKeywords: setKeywordsAndClearMatches,
        selectedClasses,
        setSelectedClasses,
        parsedDocument,
        predictionsInfo: predictionsInfo ?? [],
        //
        tab,
        setTab,
        openDefaultTab,
        openEditTab,
      }}
    >
      <div className="flex w-full h-full overflow-hidden justify-between">
        <Left keywords={keywords} setKeywords={setKeywordsAndClearMatches} />
        <Right />
      </div>
    </DocumentContext.Provider>
  )
}
