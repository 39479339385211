import { useState } from "react"
import { ChevronDown, Settings2, Eye, EyeOff } from "lucide-react"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible"
import { KeywordSearchResults } from "./keyword-search-results"
import { SemanticSearchResults } from "./semantic-search-results"
import { useDocumentContext } from "../DocumentContext"
import ClassSelectionModal from "./class-selection-modal"
import { ddCriteria, DdCriterion } from "@/data/criteria"
import DocumentOverview from "../DocumentOverview"
import ChatView from "./chat-view"
import SectionsView from "./sections-view"

export default function CollapsibleList() {
  const { keywords, selectedClasses, setSelectedClasses, predictionsInfo, documentId, document } = useDocumentContext()
  // TODO: this should be used in "right"
  const [hiddenState, setHiddenState] = useState<{ [i: number]: boolean }>({
    0: false,
    1: false,
    2: false,
  })
  const [isOptionsOpen, setOptionsOpen] = useState(false)
  const items = []

  if (keywords.length > 0) {
    items.push({
      id: 1,
      title: "Keyword search",
      content: <KeywordSearchResults />,
      options: null,
      defaultOpen: true,
      style: ""
    })
  }

  if (true) {
    items.push({
      id: 2,
      title: `Interesting clauses (${selectedClasses.length}/${predictionsInfo.length} selected)`,
      content: <SemanticSearchResults selectedClasses={selectedClasses} />,
      options: (
        <ClassSelectionModal
          isOpen={isOptionsOpen}
          setOpen={setOptionsOpen}
          selectedClasses={selectedClasses}
          setSelectedClasses={setSelectedClasses}
          availableClasses={predictionsInfo}
        />
      ),
      defaultOpen: false,
      style: "bg-zinc-50"
    })
  }

  items.push({
    id: 0,
    title: "Document Overview",
    content: <DocumentOverview />,
    options: null,
    defaultOpen: true,
    style: ""
  })

  items.push({
    id: 3,
    title: "Chat",
    content: <ChatView projectId={document?.project_id ?? null} documentId={documentId} />,
    options: null,
    defaultOpen: true,
    style: ""
  })

  items.push({
    id: 4,
    title: "Sections",
    content: <SectionsView />,
    options: null,
    defaultOpen: true,
    style: ""
  })
  
  return (
    <div className="w-full max-w-2xl mx-auto p-4 space-y-4 bg-background">
      {items.map((item) => (
        <Collapsible key={item.id} defaultOpen={item.defaultOpen}>
          <CollapsibleTrigger className="flex items-center justify-between w-full px-4 py-3 font-medium text-left rounded bg-zinc-100 hover:bg-muted/90 top-0 z-1">
            {item.title}
            <div className="flex gap-4">
              {/* {hiddenState[item.id] ? (
                <Eye
                  className="h-4 w-4"
                  onClick={(e) => {
                    e.stopPropagation()
                    setHiddenState({
                      ...hiddenState,
                      [item.id]: !hiddenState[item.id],
                    })
                  }}
                />
              ) : (
                <EyeOff
                  className="h-4 w-4"
                  onClick={(e) => {
                    e.stopPropagation()
                    setHiddenState({
                      ...hiddenState,
                      [item.id]: !hiddenState[item.id],
                    })
                  }}
                />
              )} */}

              {item.options && (
                <Settings2
                  className="h-4 w-4"
                  onClick={(e) => {
                    e.stopPropagation()
                    setOptionsOpen(true)
                  }}
                />
              )}
              <ChevronDown className="h-4 w-4 transition-transform duration-200" />
            </div>
          </CollapsibleTrigger>
          {item.options && (
            <div onClick={(e) => e.stopPropagation()}>{item.options}</div>
          )}
          {/* bg-zinc-50 */}
          <CollapsibleContent className={`p-4 pt-2 text-sm rounded-b border-x border-b border-zinc-100 ${item.style}`}>
            {item.content}
          </CollapsibleContent>
        </Collapsible>
      ))}
    </div>
  )
}
