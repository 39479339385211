import { useState } from "react"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
//   DialogTrigger,
} from "@/components/ui/dialog"
import { ddCriteria, DdCriterion } from "@/data/criteria";  // TODO: take this from backend
import { DocumentPredictionsInfoSchema } from "@/api/resources/types";


interface ModalParams {
    isOpen: boolean
    setOpen: (x: boolean) => void
    selectedClasses: DocumentPredictionsInfoSchema[]
    setSelectedClasses: (x: DocumentPredictionsInfoSchema[]) => void
    availableClasses: DocumentPredictionsInfoSchema[]
}

export default function ClassSelectionModal({ isOpen, setOpen, selectedClasses, setSelectedClasses, availableClasses } : ModalParams) {
  const [checkedClasses, setCheckedClasses] = useState<DocumentPredictionsInfoSchema[]>(selectedClasses)

  const handleCheckboxChange = (item: DocumentPredictionsInfoSchema) => {
    setCheckedClasses((prev) =>
      prev.includes(item) ? prev.filter((x) => x !== item) : [...prev, item]
    )
  }

  const handleOk = () => {
    setSelectedClasses(checkedClasses)
    setOpen(false)
  }

  const handleCancel = () => {
    setCheckedClasses(selectedClasses)
    setOpen(false)
  }

  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      {/* <DialogTrigger asChild>
        <Button variant="outline">Open Modal</Button>
      </DialogTrigger> */}
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Select Classes</DialogTitle>
        </DialogHeader>
        <div className="grid grid-cols-2 gap-4">
          {availableClasses.map((item) => (
            <div key={item.class_id} className="flex items-center space-x-2">
              <Checkbox
                id={item.class_id}
                checked={checkedClasses.includes(item)}
                onCheckedChange={() => handleCheckboxChange(item)}
              />
              <label
                htmlFor={item.class_id}
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {item.class_name} 
                {
                  item.model_name && (
                    <span className="font-normal text-xs text-gray-400"><br/>{item.model_name}{item.model_version ? ` v${item.model_version}` : ""}</span>
                  )
                }
                {
                  item.labeled_by && (
                    <span className="font-normal text-xs text-gray-400"><br/>+ {item.labeled_by.first_name} {item.labeled_by.last_name}</span>
                  )
                }
              </label>
            </div>
          ))}
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleOk}>OK</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

