import { Check, ChevronsUpDown, Plus } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { ClassSchema, TrainedModelSchema } from "@/api/resources/types"
import { useAssignModelToClassMutation, useDeleteClassMutation, useGetClassesQuery, useGetModelsForClassQuery } from "@/api/resources"
import { Link, useNavigate } from "react-router-dom"
import CreateClassModal from "./create-class-modal"
import { DeleteClassModal } from "./delete-class-modal"
import { cn } from "@/lib/utils"

import { Command, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command"
import { CommandEmpty } from "@/components/ui/command"
import { CommandList } from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"

const AssignModelToClass = ({ class_ }: { class_: ClassSchema }) => {
  const { data: models, isLoading: modelsLoading } = useGetModelsForClassQuery(class_?.id ?? "", { skip: !class_?.id })
  const selectedModel = models?.find(model => model.id === class_.active_model_id)
  const [assignModelToClass] = useAssignModelToClassMutation()

  const handleModelSelect = (modelId: string | null) => {
    if (modelId) {
      assignModelToClass({ class_id: class_.id, model_id: modelId })
    } else {
      // remove model assignment
      assignModelToClass({ class_id: class_.id, model_id: null })
    }
  }
  
  return (
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" role="combobox" className="w-64 justify-between">
            {selectedModel
              ? selectedModel.name
              : "Select model..."}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-64 p-0">
          <Command>
            <CommandInput placeholder="Search models..." />
            <CommandList>
              <CommandEmpty>{modelsLoading ? "Models are loading ..." : "No models found."}</CommandEmpty>
              <CommandGroup>
                {models?.map((model) => (
                  <CommandItem
                    key={model.id}
                    onSelect={() => handleModelSelect(model.id)}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        model.class_id === class_.id ? "opacity-100" : "opacity-0",
                      )}
                    />
                    {model.name} version {model.version}
                  </CommandItem>
                ))}
                <CommandItem key="no-identify" onSelect={() => handleModelSelect(null)}>
                  Don't identify
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    )
}


export default function ClassesList() {
  const navigate = useNavigate()
  const { data: classes, isLoading } = useGetClassesQuery()
  const [deleteClass, { isLoading: isDeleting }] = useDeleteClassMutation()

  const handleCreateNew = () => {
    // Implement the logic to create a new dataset
    console.log("Create new class")
  }

  if (isLoading) return <div>Loading...</div>

  return (
    <div className="container mx-auto py-10">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Classes</h1>
        <CreateClassModal />
      </div>
      <p className="font-medium">A class is any type of clause that you want to flag in your documents.</p>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Created At</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {classes?.map((cls) => (
            <TableRow key={cls.id}>
              <TableCell className="font-medium">{cls.name}</TableCell>
              <TableCell>{cls.description}</TableCell>
              <TableCell>{new Date(cls.created_at).toLocaleString()}</TableCell>
              <TableCell>
                <AssignModelToClass class_={cls} />
              </TableCell>
              <TableCell>
                <DeleteClassModal className={cls.name} onConfirm={() => deleteClass(cls.id)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
