import { Link } from "react-router-dom"
import ProjectDropdown from "@/components/projects/ProjectDropdown"
import { ProjectSchema } from "@/api/resources/types"
import ProgressBar from "@/components/Project/folders/ProgressBar"
import { Card } from "../ui/card"
import { BriefcaseBusinessIcon } from "lucide-react"

interface ProjectsProps {
  projects: ProjectSchema[]
  className?: string
}

export default function Projects({ projects }: ProjectsProps) {
  return (
    <div className="grid gap-4 grid-cols-4">
      {projects.map((project, _) => (
        <Card className="flex flex-col gap-2 justify-between p-6 pr-4 shadow-sm" key={project.project_id}>
          <div className="flex justify-between items-center">
            <div className="flex gap-1 items-center">
              <BriefcaseBusinessIcon className="w-7 h-7 stroke-[1.5px] text-slate-600 mr-1" />
              <p className="bg-blue-200 text-white px-2 py-0.5 text-xs rounded-full capitalize font-semibold">
                {project.role}
              </p>
            </div>
            <ProjectDropdown project={project} />
          </div>

          <div className="flex flex-col flex-1">
            <Link
              className="text-16 text-gray-500 flex-1 break-words"
              to={`/projects/${project.project_id}`}
            >
              {project.display_name}
            </Link>

            {project.total_documents === 0 ? (
              <p>No files</p>
            ) : project.completed_documents === project.total_documents ? (
              <p className="text-text-green">Done</p>
            ) : (
              <p className="text-14 leading-6 text-gray-400">
                <span className="text-black">
                  {project.completed_documents ?? 0}
                </span>{" "}
                of <span>{project.total_documents ?? 0}</span> files done
              </p>
            )}

            <ProgressBar
              percentage={
                project.total_documents !== 0
                  ? (project.completed_documents / project.total_documents) *
                    100
                  : 0
              }
            />
          </div>
        </Card>
      ))}
    </div>
  )
}
