"use client"

import type React from "react"

import { useState } from "react"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Textarea } from "@/components/ui/textarea"
import { useToast } from "@/components/ui/use-toast"
import { PlusIcon } from "lucide-react"
import { useCreateClassMutation } from "@/api/resources"

export default function CreateClassModal() {
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [open, setOpen] = useState(false)
  const { toast } = useToast()
  const [createClass, { isLoading }] = useCreateClassMutation()
  
  // Generate class_id from name: lowercase and replace spaces with underscores
  const classId = name.toLowerCase().replace(/\s+/g, "_")

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (!name.trim()) {
      toast({
        title: "Error",
        description: "Class name is required",
        variant: "destructive",
      })
      return
    }
    
    createClass({
      name,
      description,
    }).unwrap().then((res) => {
        setOpen(false)
        setName("")
        setDescription("")
    }).catch((err) => {
        toast({
          title: "Error",
          description: "Failed to create class",
          variant: "destructive",
        })
    })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <PlusIcon className="mr-2 h-4 w-4" /> Create Class
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>Create New Class</DialogTitle>
            <DialogDescription>
              Add a new class of clauses that should be identified in your documents.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="space-y-2">
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Change of control"
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="description">Description (Optional)</Label>
              <Textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter class description..."
                className="resize-none"
                rows={3}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="class_id">Class ID</Label>
              <Input id="class_id" value={classId} readOnly className="bg-muted text-slate-500" aria-label="Generated class ID" />
              {/* <p className="text-sm text-muted-foreground">
                This ID is automatically generated based on the class name
              </p> */}
            </div>
          </div>
          <DialogFooter>
            <Button type="button" variant="outline" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button type="submit" disabled={isLoading}>Create Class</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}
