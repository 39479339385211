import { cn } from "@/lib/utils"
import { useDocumentContext } from "../DocumentContext"
import { ChevronRight } from "lucide-react"

interface Section {
    id: string
    title: string
    description?: string
    level?: number
    isActive?: boolean
  }

interface SectionItemProps {
    section: Section
    onClick?: (section: Section) => void
    showDescription?: boolean
    linkPrefix: string
  }


  function SectionItem({ section, onClick, showDescription, linkPrefix }: SectionItemProps) {
    const handleClick = () => {
      onClick?.(section)
    }
  
    const indentLevel = section.level || 0
    const paddingLeft = `${indentLevel * 0.75}rem`
  
    // TODO: this should be GeistSans
    return (
      <div
        //href={`${linkPrefix}${section.id}`}
        className={cn(
          "group flex flex-col rounded-md px-3 py-2 text-sm transition-colors cursor-pointer",
          section.isActive
            ? "bg-blue-100 text-blue-600 font-medium"
            : "text-gray-500 hover:bg-gray-100 hover:text-gray-900",
        )}
        style={{ paddingLeft: indentLevel > 0 ? paddingLeft : undefined }}
        onClick={handleClick}
      >
        <div className="flex items-center justify-between">
          <span className="line-clamp-1">{section.title}</span>
          <ChevronRight
            className={cn(
              "h-4 w-4 shrink-0 transition-transform",
              section.isActive ? "text-blue-600" : "text-gray-500 group-hover:text-gray-900",
            )}
          />
        </div>
        {showDescription && section.description && (
          <span className="mt-1 line-clamp-2 text-xs text-gray-500">{section.description}</span>
        )}
      </div>
    )
  }
  
  
  

export default function SectionsView () {
    const { parsedDocument } = useDocumentContext()
    if (!parsedDocument) {
        return <div></div>
    }

    const sections = parsedDocument.text_chunks.filter((chunk) => chunk.label === "Title/SectionName").map((chunk, ix) => {
        return {
            id: ix.toString(),
            title: chunk.text,
            // TODO: page numbers should be until the start of the next section
            description: chunk.pages.length > 1 ? 
                "Pages " + (chunk.pages[0] + 1).toString() + " - " + (chunk.pages[chunk.pages.length - 1] + 1).toString() 
                : 
                "Page " + (chunk.pages[0] + 1).toString(),
            isActive: ix === 0
        }
    })

    return (
        <div className="space-y-1">
            <div className="text-xs text-gray-500 mb-2 px-3">
                {sections.length} sections detected in the document
            </div>
            {
                sections.map((section, i) => {
                    return (
                        <SectionItem key={i} section={section} linkPrefix={""} showDescription={true} />
                    )
                })
            }
        </div>
    )
}