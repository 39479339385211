import React from "react"
import ReactDOM from "react-dom/client"
import { Provider as ReduxProvider } from "react-redux"
import { Navigate } from "react-router"
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom"
import { ThemeProvider } from "styled-components"

import "./index.css"

import AppPage from "@/AppPage"
import MyLibrary from "@/app/my-library/page"
import FolderPage from "@/app/projects/[project]/[folder]/page"
import ProjectPage from "@/app/projects/[project]/page"
import Projects from "@/app/projects/page"
import Login from "@/app/auth/login"
import MetricsPage from "@/app/admin/metrics-page"

import { Dashboard } from "@/components"
import { ViewProvider } from "@/context/ViewContext"
import { store } from "@/redux/store"
import Document from "./pages/document"
import { UploadProvider } from "@/UploadContext"
import { Toaster } from "@/components/ui/sonner"
import AuthGuard from "./components/AuthGuard"
// import DatasetsList from "./pages/datasets/DatasetsList"
import CreateDataset from "./pages/datasets/CreateDataset"
import DataLabeling from "./pages/datasets/DataLabeling"
import TabbedLayout from "./pages/datasets"
import UserManagement from "./app/admin/user-management"
import SetupAccountPage from "./app/auth/login/setup-account"
import ForgotPasswordPage from "./app/auth/login/forgot-password"
import ResetPasswordPage from "./app/auth/login/reset-password"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/dashboard" />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/setup-account",
    element: <SetupAccountPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordPage />,
  },
  {
    element: <AuthGuard />,
    children: [
      {
        path: "/dashboard",
        element: (
          <AppPage>
            <Dashboard />
          </AppPage>
        ),
      },
      {
        path: "/projects",
        element: <Outlet />,
        children: [
          {
            path: "/projects",
            element:(
              <AppPage>
                <Projects />
              </AppPage>
            ),
          },
          {
            path: "/projects/:id",
            element: (
              <AppPage>
                <ProjectPage />
              </AppPage>
            ),
          },
          {
            path: "/projects/:id/metrics",
            element: (<AppPage><MetricsPage /></AppPage>),
          },
        ],
      },
      {
        path: "/document",
        element: <Document />,
      },
      {
        path: "/document/:id",
        element: <Document />,
      },
      {
        path: "/saved-searches",
        element: (
          <AppPage>
            <MyLibrary />
          </AppPage>
        ),
      },
      {
        path: "/folders/:id",
        element: (
          <AppPage>
            <FolderPage />
          </AppPage>
        ), // Ensure ProjectPage is wrapped in AppPage if needed
      },
      { 
        path: "/datasets",
        element: (
          <AppPage>
            <TabbedLayout />
          </AppPage>
        ),
      },
      {
        path: "/datasets/create",
        element: (
          <AppPage>
            <CreateDataset />
          </AppPage>
        ),
      },
      {
        path: "/datasets/:id",
        element: (
          <AppPage>
            <DataLabeling />
          </AppPage>
        ),
      },
      {
        path: "/admin/users",
        element: (
          <AppPage>
            <UserManagement />
          </AppPage>
        ),
      },
    ],
  },
])

const theme = {}

async function enableMocking() {
  if (process.env.NODE_ENV !== "development" || true) {
    return
  }

  console.log("*** USING MOCK BACKEND ***")
  const { worker } = await import("./mock/browser.js")

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}

const root = ReactDOM.createRoot(document.getElementById("root")!)
enableMocking().then(() => {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <ReduxProvider store={store}>
          <ViewProvider>
            {" "}
            {/* TODO: replace this */}
            <UploadProvider>
              <RouterProvider router={router} />
            </UploadProvider>
            <Toaster duration={3000} closeButton />
          </ViewProvider>
        </ReduxProvider>
      </ThemeProvider>
    </React.StrictMode>,
  )
})
