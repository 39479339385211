import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Icon } from "@/assets"
import { useState } from "react"
import { DeleteProjectDialog } from "@/components/allProjects/DeleteProjectDialog"
import { ProjectSchema } from "@/api/resources/types"
import { CreateOrRenameProjectDialog } from "@/components/allProjects/CreateOrRenameProjectDialog"
import ReanalyzeModal from "../Project/reanalyze-modal"
import { Edit3Icon, FolderSync, Trash2Icon } from "lucide-react"
import { useReanalyzeDocumentsMutation } from "@/api/resources"
import { toast } from "sonner"
interface Props {
  project: ProjectSchema
}

export default function ProjectDropdown({ project }: Props) {

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isRenameModal, setRenameModal] = useState(false)
  const [isDeleteModal, setDeleteModal] = useState(false)
  // const [isShareModal, setShareModal] = useState(false)
  const [isReanalyzeModal, setReanalyzeModal] = useState(false)

  const [ reanalyzeDocuments ] = useReanalyzeDocumentsMutation()

  return (
    <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownMenuTrigger>
        <Icon name="More" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
      <DropdownMenuItem
          className="mb-1"
          onClick={() => {
            setIsDropdownOpen(false)
            setReanalyzeModal(true)
          }}
        >
          <FolderSync className="h-4 mr-1" />
          Reanalyze
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            setIsDropdownOpen(false)
            setRenameModal(true)
          }}
        >
          <Edit3Icon className="h-4 mr-1" />
          Rename
        </DropdownMenuItem>
        {/* <DropdownMenuItem
          onClick={() => {
            setShareModal(true)
          }}
        >
          <Icon name="Share" className={ICON_STYLE} />
          Share
        </DropdownMenuItem> */}
        <DropdownMenuItem
          disabled={isDeleteModal}
          onClick={() => {
            setIsDropdownOpen(false)
            setDeleteModal(true)
          }}
        >
          <Trash2Icon className="h-4 mr-1" />
          Delete
        </DropdownMenuItem>
      </DropdownMenuContent>

      <CreateOrRenameProjectDialog
        projectId={project.project_id}
        initialName={project.display_name}
        action="rename"
        open={isRenameModal}
        onOpenChange={setRenameModal}
      />
      <DeleteProjectDialog
        projectId={project.project_id}
        open={isDeleteModal}
        onOpenChange={setDeleteModal}
      />
      <ReanalyzeModal
        projectId={project.project_id ?? undefined}
        open={isReanalyzeModal}
        setOpen={(x) => setReanalyzeModal(x)}
        onSubmit={async (run_ocr, run_class_identification) => {
          if (!project.project_id) {
            return
          }
          try {
            await reanalyzeDocuments({ 
              project_id: project.project_id, 
              base_resource_id: project.project_id, 
              run_ocr, 
              run_class_identification 
            }).unwrap()
            toast.success("Task submitted")
            // FIXME: the endpoint doesn't work for projects for some reason!
          } catch (error) {
            toast.error("Error submitting task")
          }
        }}
      />
    </DropdownMenu>
  )
}
