import { useState } from "react"
import Action from "./action"
import CurrentPath from "./currentPath"
import { useGetDirectoryQuery, useGetProjectQuery } from "@/api/resources"
import ProjectContext from "@/components/Project/ProjectContext"
import { FilterProvider, FiltersView, Sidebar, useFilterContext } from "../Project/project/Filtering"
import KeywordsTab from "../Project/project/main/keywordsTab"
import SemanticTab from "../Project/project/main/semanticTab"

interface FolderProps {
  folderId?: string
}

function FolderContent() {
  const { provision } = useFilterContext()
  return provision == null ? <KeywordsTab /> : <SemanticTab />
}

export default function Folder({ folderId }: FolderProps) {
  const { data: folder } = useGetDirectoryQuery(folderId)
  const { data: project } = useGetProjectQuery(
    { project_id: folder?.project_id },
    { skip: !folder },
  )

  return (
    <ProjectContext.Provider value={project}>
      {/* <DocumentSearch folder={folder} /> */}
      {project && (
        <FilterProvider folderId={folderId}>
      <div className="flex w-full h-full gap-1">
      <Sidebar />
      <div className="basis-0 w-0 grow h-full max-w-[1280px] m-auto">
        <CurrentPath folder={folder} />
        <Action folder={folder} />
        <div className="py-4 px-1 w-full max-w-[1280px] m-auto overflow-y-scroll">
              <FiltersView />
              <FolderContent />
        </div>
      </div>
      </div>
        </FilterProvider>
      )}
    </ProjectContext.Provider>
  )
}
