import { Icon } from "@/assets"
import { useState } from "react"
import Keywords from "../keywords"
import { useGetSavedSearchesQuery } from "@/api/resources"
import ActionDropdown, { DROPDOWN_ICON_STYLE } from "@/elements/ActionsDropdown"
import { CreateOrEditSearchModal } from "@/components/mySavedSearches/CreateOrEditSearchModal"
import { DeleteSearchModal } from "@/components/mySavedSearches/DeleteSearchModal"
import { SavedSearchSchema } from "@/api/resources/types"
import { Bookmark } from "lucide-react"

interface Props {
  onSelect?: (
    savedSearch: SavedSearchSchema,
    // action: "select" | "edit" | "delete",
    // ^ that is, if we wanted to push the modals outside for some reason
  ) => void
}

export default function SavedSearches({ onSelect }: Props) {
  const { data: searchData } = useGetSavedSearchesQuery()
  // which item has a currently open dropdown:
  const [openMore, setOpenMore] = useState<number | null>(null)
  // which item to show in open modals:
  const [editingModalItem, setEditingModalItem] =
    useState<SavedSearchSchema | null>(null)
  const [deletingModalItem, setDeletingModalItem] =
    useState<SavedSearchSchema | null>(null)

  const modalActions = [
    {
      icon: <Icon name="Edit" className={DROPDOWN_ICON_STYLE} />,
      label: "Edit",
      onClick: (e) => {
        setEditingModalItem(searchData[openMore])
        e.stopPropagation()
      },
    },
    {
      icon: <Icon name="Delete" className={DROPDOWN_ICON_STYLE} />,
      label: "Delete",
      onClick: (e) => {
        setDeletingModalItem(searchData[openMore])
        e.stopPropagation()
      },
    },
  ]

  return (
    <>
      <CreateOrEditSearchModal
        isOpen={editingModalItem != null}
        setOpen={(x) => {
          setEditingModalItem(x ? editingModalItem : null)
        }}
        savedSearch={editingModalItem}
      />
      <DeleteSearchModal
        open={deletingModalItem != null}
        onOpenChange={(x) => {
          setDeletingModalItem(x ? deletingModalItem : null)
        }}
        searchId={deletingModalItem?.search_id}
      />

      <div className="flex flex-col gap-4">
        {searchData?.map((savedSearch, idx) => (
          <div
            key={idx}
            className="flex justify-between items-center rounded-8 p-3 border hover:border-slate-200 border-transparent cursor-pointer"
            onClick={() => {
              onSelect && onSelect(searchData[idx]) //, "select")
            }}
          >
            <div className="flex gap-4 items-center">
              <Bookmark className="w-[24px]" />
              <div className="flex flex-col flex-wrap gap-2 justify-between">
                <p className="font-500 text-15 leading-6 text-gray-500">
                  {savedSearch.search_name}
                </p>
                <Keywords
                  keywords={savedSearch.keywords}
                  parentClass={"items-center"}
                  childClass={
                    "bg-background-gray rounded-6 p-2 text-text-neutral font-500 text-14 leading-3"
                  }
                  iconRight={false}
                />
              </div>
            </div>
            <div className="relative">
              {
                <ActionDropdown
                  isOpen={openMore === idx}
                  setOpen={(x) => {
                    setOpenMore(x ? idx : null)
                  }}
                  items={modalActions}
                  trigger={
                    <button
                      onClick={(e) => {
                        e.stopPropagation() // this stops modal from closing
                      }}
                    >
                      <Icon name="More" className="rotate-90" />
                    </button>
                  }
                />
              }
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
