import { useState } from "react"
import * as XLSX from "xlsx"
import { Upload, FileUp, Check, ChevronsUpDown } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import axios from "axios"
import { API_URL } from "@/config"
import { useAppSelector } from "@/redux/hooks"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"
import { Input } from "@/components/ui/input"
import { Command, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command"
import { CommandEmpty } from "@/components/ui/command"
import { CommandList } from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { useGetClassesQuery } from "@/api/resources"
import { cn } from "@/lib/utils"

export default function ExcelUpload() {
  const accessToken = useAppSelector((state) => state.auth.accessToken)
  const navigate = useNavigate()

  const { data: classes, isLoading: classesLoading } = useGetClassesQuery()

  const [file, setFile] = useState<File | null>(null)
  const [data, setData] = useState<any[]>([])
  const [headers, setHeaders] = useState<string[]>([])
  const [selectedColumn, setSelectedColumn] = useState<number>(0)
  const [selectedClass, setSelectedClass] = useState<string|null>(null)
  const [useDummyData, setUseDummyData] = useState(false)
  const [datasetName, setDatasetName] = useState("")

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setFile(file)
      setUseDummyData(false)
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer)
        const workbook = XLSX.read(data, { type: "array" })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) as any[][]

        const headers = jsonData[0] as string[]
        const rows = jsonData.slice(1).map((row) => {
          const rowData: { [key: string]: any } = {}
          headers.forEach((header, index) => {
            rowData[header] = row[index]
          })
          return rowData
        })

        setHeaders(headers)
        setData(rows)
        setSelectedColumn(0)
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const generateDummyData = () => {
    const dummyHeaders = ["Name", "Age", "City"]
    const dummyData = [
      { Name: "John Doe", Age: "30", City: "New York" },
      { Name: "Jane Smith", Age: "25", City: "Los Angeles" },
      { Name: "Bob Johnson", Age: "45", City: "Chicago" },
      { Name: "Alice Brown", Age: "35", City: "Houston" },
      { Name: "Charlie Davis", Age: "28", City: "Phoenix" },
    ]
    setHeaders(dummyHeaders)
    setData(dummyData)
    setSelectedColumn(0)
    setUseDummyData(true)
    setFile(null)
  }

  const handleSubmit = async () => {
    try {
      const formData = new FormData()
      if (!file || !datasetName || !selectedClass) {
        toast.error("Please fill in all fields.")
        return;
      }
      formData.append('file', file)
      formData.append('name', datasetName)
      formData.append('description', "")
      formData.append('class_id', selectedClass)
      formData.append('selectedColumn', selectedColumn.toString())
      
      const response = await axios.post(`${API_URL}/datasets/create`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      const data = await response.data
      navigate(`/datasets/${data.id}`)
    } catch (error) {
      console.error("Upload failed:", error)
      if (axios.isAxiosError(error) && error.code === "ERR_BAD_REQUEST") {
        toast.error(`Cannot import file: ${error.response?.data?.detail}`)
      } else {
        toast.error("Unknown error has occurred. Please try again.")
      }
    }
  }

  return (
    <div className="container mx-auto p-4 max-w-4xl">
      <h1 className="text-2xl font-bold mb-4">Import Data from Excel</h1>

      <div className="mb-4">
        <label htmlFor="dataset-name" className="block text-sm font-medium text-gray-700 mb-1">
          Dataset Name
        </label>
        <Input
          id="dataset-name"
          placeholder="For example: Change of Control clauses from 2024"
          className="w-full"
          required
          value={datasetName}
          onChange={(e) => setDatasetName(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="class-select" className="block text-sm font-medium text-gray-700 mb-1">
          What class will this dataset identify?
        </label>
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" role="combobox" className="w-64 justify-between">
              {selectedClass
                ? classes?.find(cls => cls.id === selectedClass)?.name
                : "Select class..."}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-64 p-0">
            <Command>
              <CommandInput placeholder="Search classes..." />
              <CommandList>
                <CommandEmpty>{classesLoading ? "Classes are loading ..." : "No classes found."}</CommandEmpty>
                <CommandGroup>
                  {classes?.map((cls) => (
                    <CommandItem
                      key={cls.id}
                      onSelect={() => setSelectedClass(cls.id)}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          selectedClass === cls.id ? "opacity-100" : "opacity-0",
                        )}
                      />
                      {cls.name}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>

      <div className="mb-4">
        <label htmlFor="file-upload" className="cursor-pointer">
          <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center hover:border-gray-400 transition-colors">
            {file ? (
              <div className="text-green-500 flex items-center justify-center">
                <Check className="mr-2" />
                <span>{file.name}</span>
              </div>
            ) : (
              <div className="text-gray-500">
                <FileUp className="mx-auto mb-2" />
                <span>Click to upload or drag and drop</span>
              </div>
            )}
          </div>
          <input id="file-upload" type="file" accept=".xlsx, .xls" onChange={handleFileUpload} className="hidden" />
        </label>
      </div>

      <div className="flex justify-between items-center mb-4">
        <Button onClick={generateDummyData} variant="outline">
          Use Dummy Data
        </Button>
        {file && <p className="text-sm text-gray-500">File: {file.name}</p>}
      </div>

      {headers.length > 0 && (
        <div className="mb-4">
          <label htmlFor="column-select" className="block text-sm font-medium text-gray-700 mb-1">
            Select Target Column
          </label>
          <Select onValueChange={(value) => setSelectedColumn(headers.indexOf(value))} value={headers[selectedColumn]}>
            <SelectTrigger id="column-select">
              <SelectValue placeholder="Select target column" />
            </SelectTrigger>
            <SelectContent>
              {headers.map((header, index) => (
                <SelectItem key={index} value={header}>
                  {header}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}

      {data.length > 0 && (
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Data Preview</h2>
          <div className="border rounded-md">
            <Table>
              <TableHeader>
                <TableRow>
                  {headers.map((header) => (
                    <TableHead key={header}>{header}</TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.slice(0, 5).map((row, index) => (
                  <TableRow key={index}>
                    {headers.map((header) => (
                      <TableCell key={`${index}-${header}`}>{row[header]}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <p className="text-sm text-gray-500 mt-2">
            Showing {Math.min(data.length, 5)} rows of {data.length}
          </p>
        </div>
      )}

      <Button onClick={handleSubmit} disabled={(!file && !useDummyData) || selectedColumn === undefined || !datasetName || !selectedClass} className="w-full">
        <Upload className="mr-2" />
        Process Data
      </Button>
    </div>
  )
}
