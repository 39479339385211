import { useEffect, useState } from "react"
import {
  useCreateSavedSearchMutation,
  useEditSavedSearchMutation,
} from "@/api/resources"
import { Modal } from "@/elements"
import CreateSearch from "@/components/mySavedSearches/createSearch"
import Keywords from "@/components/keywords"
import Search, {
  handlePasteFunction,
} from "@/components/left/main/searchBar/search"

interface ModalProps {
  isOpen: boolean
  setOpen: (open: boolean) => void
  savedSearch?: any
  initKeywords: string[]
}

export function CreateOrEditSearchModal({
  isOpen,
  setOpen,
  savedSearch,
  initKeywords,
}: ModalProps) {
  const [query, setQuery] = useState<string>("")
  const [keywords, setKeywords] = useState<string[]>(initKeywords ?? [])
  const [searchError, setSearchError] = useState<string | null>(null)
  const [createSavedSearch, createResult] = useCreateSavedSearchMutation()
  const [editSavedSearch, editResult] = useEditSavedSearchMutation()

  useEffect(() => {
    if (savedSearch) {
      setKeywords(savedSearch.keywords)
    } else {
      setKeywords(initKeywords ?? [])
    }
  }, [savedSearch])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery(e.target.value)

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      setKeywords([...keywords, query])
    }
  }

  const deleteKeyword = (idx: number) => {
    const existingKeywords = [...keywords]
    existingKeywords.splice(idx, 1)
    setKeywords(existingKeywords)
  }

  const handleSave = (e: { preventDefault: () => void }, name: string) => {
    if (keywords.length === 0) setSearchError("Insert at least one keyword")
    else {
      if (searchError) setSearchError(null)
      if (!!savedSearch) {
        editSavedSearch({
          search_name: name,
          keywords,
          search_id: savedSearch.search_id,
        })
      } else {
        createSavedSearch({ search_name: name, keywords })
      }
    }
  }

  const handlePaste = handlePasteFunction(keywords, setKeywords)

  return (
    <Modal isOpen={isOpen} setOpen={setOpen}>
      <CreateSearch
        savedSearch={savedSearch}
        closeModal={() => {
          setOpen(false)
        }}
        handleSave={handleSave}
      >
        <>
          <Keywords
            keywords={keywords}
            parentClass={"py-2 flex-wrap"}
            deleteKeyword={deleteKeyword}
          />
          <Search
            placeholder="Type keywords ⏎, or paste from excel"
            classname="py-4"
            query={query}
            handleChange={handleChange}
            handleKeyPress={handleKeyPress}
            handlePaste={handlePaste}
          />
          {searchError && (
            <p className="text-background-lightRed font-500 text-14 leading-6 text-center">
              {searchError}
            </p>
          )}
        </>
      </CreateSearch>
    </Modal>
  )
}
