"use client"

import type React from "react"

import { useState } from "react"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"

interface ReanalyzeModalProps {
  documentId?: string
  folderId?: string
  projectId?: string
  onSubmit: (run_ocr: boolean, run_class_identification: boolean) => void
  open: boolean
  setOpen: (open: boolean) => void
}

export default function ReanalyzeModal({ open, setOpen, documentId, folderId, projectId, onSubmit }: ReanalyzeModalProps) {
  const [runOcr, setRunOcr] = useState(false)
  const [runClassIdentification, setRunClassIdentification] = useState(true)

  const getTitle = () => {
    if (documentId) return "Reanalyze document"
    if (folderId) return "Reanalyze folder"
    if (projectId) return "Reanalyze project"
    return "Reanalyze"
  }

  const handleSubmit = () => {
    onSubmit(runOcr, runClassIdentification)
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen} modal>
      {/* <DialogTrigger asChild>{trigger || <Button variant="outline">Reanalyze</Button>}</DialogTrigger> */}
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{getTitle()}</DialogTitle>
          <DialogDescription>Select the analysis operations you want to perform.</DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex items-center space-x-2">
            <Checkbox id="run-ocr" checked={runOcr} onCheckedChange={(checked) => setRunOcr(checked as boolean)} />
            <Label htmlFor="run-ocr">Re-run OCR and text extraction</Label>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="run-class-identification"
              checked={runClassIdentification}
              onCheckedChange={(checked) => setRunClassIdentification(checked as boolean)}
            />
            <Label htmlFor="run-class-identification">Re-run class identification</Label>
          </div>
        </div>
        {runOcr && (
          <span className="text-sm font-medium text-gray-500"><span className="text-red-500">Warning:</span> re-running OCR will delete all changes made to the document!</span>
        )}
        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
