import * as React from "react"
import { Check, ChevronsUpDown, Loader2, Plus, Trash2, User2 } from "lucide-react"

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { cn } from "@/lib/utils"
import { CollaboratorSchema, useGetClassesQuery, useLazyGetModelsForClassQuery, useGetProjectCollaboratorsQuery, useGetUsersQuery, useRemoveProjectCollaboratorMutation, useUpdateProjectCollaboratorMutation, useGetProjectClassesQuery, useAssignProjectClassMutation, useGetModelsForClassQuery, useDeleteProjectAnnotationsMutation } from "@/api/resources"
import { useAddProjectCollaboratorMutation } from "@/api/resources"
import { useProjectContext } from "../ProjectContext"
import { toast } from "sonner"
import { ClassSchema, UserSchemaAdminView } from "@/api/resources/types"
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"


const ModelSelection = ({ classId, currentModel, handleModelSelect }: { classId: string, currentModel: any, handleModelSelect: (classId: string, modelId: string|null) => void }) => {
  const [getModels, {data: models, isLoading}] = useLazyGetModelsForClassQuery()
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (open && classId && !models) {
      getModels(classId)
    }
  }, [open, classId, getModels, models])

  return (
    <Popover modal open={open} onOpenChange={(o) => setOpen(o)}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" className="min-w-64 justify-between">
          {currentModel
            ? `${currentModel.model_name} v.${currentModel.model_version}`
            : "Select model..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-64 p-0">
        <Command>
          <CommandInput placeholder="Search models..." />
          <CommandList>
            <CommandEmpty>{isLoading ? "Models are loading ..." : "No model found."}</CommandEmpty>
            <CommandGroup>
              {models?.map((model) => (
                <CommandItem
                  key={model.id}
                  onSelect={() => handleModelSelect(classId, model.id)}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      currentModel?.model_id === model.id ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {model.name} v.{model.version}
                </CommandItem>
              ))}
              <CommandItem key="_no_identify" onSelect={() => handleModelSelect(classId, null)}>
                Don't identify
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

const ModelSelectionYesOrNo = ({ class_, currentModel, handleModelSelect }: { class_: ClassSchema, currentModel: any, handleModelSelect: (classId: string, modelId: string|null) => void }) => {
  // you can only select the active model for a class and not any other model
  const handleEnableOrDisableClass = (checked: boolean) => {
    if (checked) {
      if (!class_.active_model_id) {
        toast.error("There is no model selected for this class.")
      }
      handleModelSelect(class_.id, class_.active_model_id ?? null)
    } else {
      handleModelSelect(class_.id, null)
    }
  }

  return (
    <>
    <div className="flex items-center space-x-2">
      <Switch 
        id={class_.id}
        checked={currentModel != null}
        onCheckedChange={handleEnableOrDisableClass}
      />
      <Label htmlFor={class_.id}>{class_.name}</Label>
    </div>
    </>
  )
}

const DeleteClassModal = ({ projectId, classId, open, setOpen }: { projectId: string, classId: string, open: boolean, setOpen: (open: boolean) => void }) => {

  const [deleteProjectAnnotations, deleteResult] = useDeleteProjectAnnotationsMutation()

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete Class</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Are you sure you want to delete all annotations for this class? This action cannot be undone.
        </DialogDescription>
        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="destructive" onClick={() => {
            deleteProjectAnnotations({
              project_id: projectId,
              class_id: classId
            })
            setOpen(false)
          }}>Delete</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}


const ClassesTab = ({ projectId }: { projectId: string }) => {
  const { data: classes, isLoading: classesLoading } = useGetClassesQuery()
  const { data: projectClasses, isLoading: projectClassesLoading } = useGetProjectClassesQuery(projectId)
  const [assignProjectClass, assignResult] = useAssignProjectClassMutation()
  //const [classModels, setClassModels] = React.useState<Record<number, string>>({})

  const [isDeleteClassModalOpen, setIsDeleteClassModalOpen] = React.useState(false)

  const handleModelSelect = (classId: string, model_id: string|null) => {
    assignProjectClass({
      project_id: projectId,
      class_id: classId,
      model_id: model_id
    }).unwrap().catch((error) => {
      error.data.detail ?
        toast.error(`Error assigning model to class: ${error.data.detail}`)
      :
        toast.error("Error assigning model to class. Please try again.")
    })
  }

  const projectModels = React.useMemo(() => {
    if (!projectClasses) return {}
    return projectClasses.reduce((acc, cls) => ({
      ...acc,
      [cls.class_id]: {
        model_id: cls.model_id,
        model_name: cls.model_name,
        model_version: cls.model_version
      }
    }), {}) as Record<string, any>
  }, [projectClasses])

  if (classesLoading || projectClassesLoading) {
    return <div className="flex items-center justify-center h-full">
      <Loader2 className="w-8 h-8 animate-spin" />
    </div>
  }

  if (classes === undefined) {
    return <div>
      <span>Error: The list of classes could not be loaded. Do you have permission to view this project?</span>
    </div>
  }

  return (
  <div className="space-y-6">
    <h3 className="text-lg font-medium">Class Identification</h3>
    <span className="text-sm text-gray-500">
      Changes will affect newly uploaded documents.
    </span>
    <div className="space-y-4 overflow-y-auto max-h-[500px]">
      {classes.map((cls) => (
        <div key={cls.id} className="flex items-center gap-4 justify-between">
          {/* <div className="w-32">{cls.name}</div> */}
          <ModelSelectionYesOrNo class_={cls} currentModel={projectModels[cls.id]} handleModelSelect={handleModelSelect} />
          <Button variant="ghost" size="icon" onClick={() => setIsDeleteClassModalOpen(true)}>
            <Trash2 className="h-4 w-4" />
          </Button>
          <DeleteClassModal projectId={projectId} classId={cls.id} open={isDeleteClassModalOpen} setOpen={setIsDeleteClassModalOpen} />
        </div>
      ))}
    </div>
  </div>
  )
}


const PermissionsTab = ({ projectId }: { projectId: string }) => {

    const { data: projectUsers, isLoading: projectUsersLoading } = useGetProjectCollaboratorsQuery(projectId)

    // TODO: make this lazy loading
    const { data: allUsers, isLoading: allUsersLoading } = useGetUsersQuery()
    const [addProjectCollaborator, addResult] =
      useAddProjectCollaboratorMutation()
    const [updateProjectCollaborator, updateResult] =
      useUpdateProjectCollaboratorMutation()
  
    const [removeProjectCollaborator, removeResult] =
      useRemoveProjectCollaboratorMutation()

  if (projectUsersLoading || allUsersLoading) {
    return <div className="flex items-center justify-center h-full">
        <Loader2 className="w-8 h-8 animate-spin" />
    </div>
  }

  if (allUsers === undefined || projectUsers === undefined) {
    return <div>
        <span>Error: The list of users could not be loaded. Do you have permission to view this project?</span>
    </div>
  }

  const availableUsers = allUsers.filter((user) => !projectUsers?.find((u) => u.user_id === user.user_id))

  const handleAddUser = (user: UserSchemaAdminView) => {
    addProjectCollaborator({
            project_id: projectId,
            email: user.email,
            role: "viewer"
        }).unwrap().catch((error) => {
            error.data.detail ? 
                toast.error(`Error adding user to project: ${error.data.detail}`)
            :
                toast.error("Error adding user to project. Please try again.")
        })
    }

  const handleRemoveUser = (user: CollaboratorSchema) => {
    removeProjectCollaborator({
        project_id: projectId,
        email: user.email
    }).unwrap().catch((error) => {
        error.data.detail ?
            toast.error(`Error removing user from project: ${error.data.detail}`)
        :
            toast.error("Error removing user from project. Please try again.")
    })
  }

  const handleRoleChange = (user: CollaboratorSchema, role: string) => {
    updateProjectCollaborator({
        project_id: projectId,
        email: user.email,
        role: role as "editor" | "owner" | "viewer"
    }).unwrap().catch((error) => {
        error.data.detail ?
            toast.error(`Error updating user role: ${error.data.detail}`)
        :
            toast.error("Error updating user role. Please try again.")
    })
  }

  return (
    <div className="space-y-6">
    <div className="flex items-center justify-between overflow-y-auto max-h-[500px]">
      <h3 className="text-lg font-medium">Project Members</h3>
      <Popover modal>
        <PopoverTrigger asChild>
          <Button variant="outline">
            <Plus className="mr-2 h-4 w-4" />
            Add Member
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-64 p-0" align="end">
          <Command>
            <CommandInput placeholder="Search users..." />
            <CommandList>
              <CommandEmpty>No users found.</CommandEmpty>
              <CommandGroup>
                {availableUsers.map((user) => (
                  <CommandItem key={user.user_id} onSelect={() => handleAddUser(user)}>
                    <Avatar className="mr-2 h-6 w-6">
                      <AvatarImage src={user?.photo ?? ""} />
                      <AvatarFallback>
                        {user.first_name[0]}
                        {user.last_name[0]}
                      </AvatarFallback>
                    </Avatar>
                    {user.first_name} {user.last_name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>

    <div className="space-y-4">
      {projectUsers.map((user) => (
        <div key={user.user_id} className="flex items-center justify-between gap-4 rounded-lg border p-4">
          <div className="flex items-center gap-4">
            <Avatar>
              <AvatarImage src={user.photo ?? ""} />
              <AvatarFallback>
                {user.first_name[0]}
                {user.last_name[0]}
              </AvatarFallback>
            </Avatar>
            <div>
              <div className="font-medium">
                {user.first_name} {user.last_name}
              </div>
              <div className="text-sm text-muted-foreground">{user.email}</div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <Select value={user.role} onValueChange={(value) => handleRoleChange(user, value)}>
              <SelectTrigger className="w-32">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="owner">Owner</SelectItem>
                <SelectItem value="editor">Editor</SelectItem>
                <SelectItem value="viewer">Viewer</SelectItem>
              </SelectContent>
            </Select>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => handleRemoveUser(user)}
              disabled={user.role === "owner"}
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </div>
      ))}
    </div>
  </div>
  )
}


export function ProjectSettingsModal({ open, setOpen }: { open: boolean, setOpen: (open: boolean) => void }) {
  const [activeTab, setActiveTab] = React.useState("general")
  const project = useProjectContext()
  const tabs = [
    { id: "general", label: "General" },
    { id: "identification", label: "Identification" },
    { id: "permissions", label: "Permissions" },
  ]

  if (!project) {
    return <></>
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle>Project Settings</DialogTitle>
        </DialogHeader>
        <div className="flex min-h-[60vh]">
          {/* Sidebar */}
          <div className="w-48 border-r">
            <nav className="flex flex-col gap-1 p-2">
              {tabs.map((tab) => (
                <Button
                  key={tab.id}
                  variant={activeTab === tab.id ? "secondary" : "ghost"}
                  className="justify-start"
                  onClick={() => setActiveTab(tab.id)}
                >
                  {tab.label}
                </Button>
              ))}
            </nav>
          </div>

          {/* Content */}
          <div className="flex-1 p-6">
            {activeTab === "identification" && (
              <ClassesTab projectId={project.project_id} />
            )}

            {activeTab === "permissions" && (
              <PermissionsTab projectId={project.project_id} />
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

