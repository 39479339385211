import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import axios from "axios";
import { useCallback, useState } from "react";
import { toast } from "sonner";
import TypingIndicator from "./typing-indicator";
import { AlertTriangle } from "lucide-react";
import { useAppSelector } from "@/redux/hooks";

interface Message {
    role: "user" | "assistant"
    content: string
}

const MessageElement = ({ message }: { message: Message }) => {
    return <div className={`p-2 rounded-xl ${message.role === "user" ? "mr-[5%] bg-gray-100" : "ml-[5%] bg-white"}`}>{message.content}</div>
}

export default function ChatView ({ projectId, documentId }: { projectId: string|null, documentId: string|null }) {
    const [prompt, setPrompt] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [messages, setMessages] = useState<Message[]>([])
    const authToken = useAppSelector((state) => state.auth.accessToken)

    const sendMessage = () => {
        if (!prompt || prompt.length === 0) {
            return
        }
        setIsLoading(true)
        axios.post("https://api.pactus.ai/chat", {
            user_prompt: prompt,
            project_id: projectId,
            document_id: documentId,
            // history: messages
        }, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        }).then((res) => {
            setIsLoading(false)
            setMessages((prev) =>  [...prev, { role: "assistant", content: res.data }])
        }).catch((err) => {
            setIsLoading(false)
            console.error(err)
            toast.error("Error sending message")
        })
        setMessages([{ role: "user", content: prompt }])
        setPrompt("")
    }

    return <div>
        <div className="flex flex-row gap-2">
            <Input placeholder="Ask a question" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
            <Button onClick={sendMessage} disabled={!prompt || prompt.length === 0}>Send</Button>
        </div>
        <div className="flex flex-col gap-3 mt-4">
            {
                messages.map((message) => <MessageElement key={message.content} message={message} />)
            }
            {
                isLoading && <TypingIndicator />
            }
        </div>
        <div className="text-xs mb-4 p-2 mt-5 rounded-md flex border">
            <AlertTriangle className="w-4 h-4 mr-2 min-w-4" />
            <span>
                This is a beta version using a small AI model for testing purposes. Currently, the AI only sees the text content of the first few pages without visual layout. Please note that the AI may make mistakes or provide inaccurate information. Use the responses with appropriate caution.
            </span>
        </div>
    </div>
}