import React, { MouseEvent, useContext, useState, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { Bounds } from './common';
import { Copy, Link, SquareCheck, SquareCheckBig } from "lucide-react";
// import { CloseCircleFilled, EditFilled } from '@ant-design/icons';

function hexToRgb(hex: string) {
  return "grey";
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    throw new Error('Unable to parse color.');
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
}

function getBorderWidthFromBounds(bounds: Bounds): number {
  //
  const width = bounds.right - bounds.left;
  const height = bounds.bottom - bounds.top;
  if (width < 100 || height < 100) {
    return 1;
  } else {
    return 3;
  }
}

interface SelectionBoundaryProps {
  color?: string;
  xyxy: number[];
  index: number;
  selected: boolean;
  highlighted: boolean;
  highlightColor?: string;
  children?: React.ReactNode;
  annotationId?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const StyledSpan = styled.span`
  position: absolute;
  mix-blend-mode: multiply;
  &:hover {
    background-color: #d8dfe6;
    border: 1px solid #3C6083;
  }
`;

export const SelectionBoundary = ({
  color,
  xyxy,
  index,
  children,
  onClick,
  selected,
  highlighted,
  highlightColor,
  onMouseEnter,
  onMouseLeave,
}: SelectionBoundaryProps) => {
  const width = xyxy[2] - xyxy[0] //bounds.right - bounds.left;
  const height = xyxy[3] - xyxy[1] //bounds.bottom - bounds.top;
  const rotateY = width < 0 ? -180 : 0
  const rotateX = height < 0 ? -180 : 0

  return (
    <StyledSpan
      onClick={(e) => {
        // Here we are preventing the default PdfAnnotationsContainer
        // behaviour of drawing a new bounding box if the shift key
        // is pressed in order to allow users to select multiple
        // annotations and associate them together with a relation.
        // if (e.shiftKey && onClick) {
        //   e.stopPropagation();
        //   onShiftClick();
        // }
        e.stopPropagation()
        onClick && onClick()
      }}
      onMouseDown={(e) => {
        // if (e.shiftKey && onClick) {
        //   e.stopPropagation();
        // }
        e.stopPropagation()
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`match-element-${index}`}
      style={{
        position: "absolute",
        left: `${xyxy[0] - 2.5}px`,
        top: `${xyxy[1] - 2.5}px`,
        width: `${Math.abs(width) + 5}px`,
        height: `${Math.abs(height) + 5}px`,
        transform: `rotateY(${rotateY}deg) rotateX(${rotateX}deg)`,
        transformOrigin: "top left",
        // backgroundColor: selected ? "#d8dfe6": "transparent",
        // mixBlendMode: "multiply",
        borderRadius: "2.5px",
        boxShadow: selected
          ? "rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, #a6c0fe 0px 0px 0px 2px"
          : undefined,
        background: highlighted
          ? selected
            ? "rgb(134, 239, 172, 1.0)"
            : (highlightColor ?? "rgb(134, 239, 172, 0.6)")
          : null,
      }}
    >
      {children || null}
    </StyledSpan>
  )
}

// interface TokenSpanProps {
//   isSelected?: boolean;
// }

// const TokenSpan = styled.span<TokenSpanProps>(
//   ({ theme, isSelected }) => `
//     position: absolute;
//     background: ${isSelected ? theme.color.B3 : 'none'};
//     opacity: 0.2;
//     border-radius: 3px;
// `
// );

// interface SelectionTokenProps {
//   pageInfo: PDFPageInfo;
//   tokens: TokenId[] | null;
// }
// export const SelectionTokens = ({ pageInfo, tokens }: SelectionTokenProps) => {
//   return (
//     <>
//       {tokens &&
//         tokens.map((t, i) => {
//           const b = pageInfo.getScaledTokenBounds(pageInfo.tokens[t.tokenIndex]);
//           return (
//             <TokenSpan
//               key={i}
//               isSelected={true}
//               style={{
//                 left: `${b.left}px`,
//                 top: `${b.top}px`,
//                 width: `${b.right - b.left}px`,
//                 height: `${b.bottom - b.top}px`,
//                 // Tokens don't respond to pointerEvents because
//                 // they are ontop of the bounding boxes and the canvas,
//                 // which do respond to pointer events.
//                 pointerEvents: 'none',
//               }}
//             />
//           );
//         })}
//     </>
//   );
// };

// interface EditLabelModalProps {
//   annotation: Annotation;
//   onHide: () => void;
// }
//
// const EditLabelModal = ({ annotation, onHide }: EditLabelModalProps) => {
//   const annotationStore = useContext(AnnotationStore);
//
//   const [selectedLabel, setSelectedLabel] = useState(annotation.label);
//
//   // There are onMouseDown listeners on the <canvas> that handle the
//   // creation of new annotations. We use this function to prevent that
//   // from being triggered when the user engages with other UI elements.
//   const onMouseDown = (e: MouseEvent) => {
//     e.stopPropagation();
//   };
//
//   useEffect(() => {
//     const onKeyPress = (e: KeyboardEvent) => {
//       // Ref to https://github.com/allenai/pawls/blob/0f3e5153241502eb68e46f582ed4b28112e2f765/ui/src/components/sidebar/Labels.tsx#L20
//       // Numeric keys 1-9
//       if (e.keyCode >= 49 && e.keyCode <= 57) {
//         const index = Number.parseInt(e.key) - 1;
//         if (index < annotationStore.labels.length) {
//           const selectedLabel = annotationStore.labels[index];
//           annotationStore.setPdfAnnotations(
//             annotationStore.pdfAnnotations
//               .deleteAnnotation(annotation)
//               .withNewAnnotation(annotation.update({ label: selectedLabel }))
//           );
//           onHide();
//         }
//       }
//     };
//     window.addEventListener('keydown', onKeyPress);
//     return () => {
//       window.removeEventListener('keydown', onKeyPress);
//     };
//   }, [annotationStore, annotation]);
//
//   return (
//     <Modal
//       title="Edit Label"
//       onCancel={onHide}
//       onOk={() => {
//         // Remove the annotation and add a copy with the updated label.
//         // TODO: This might have side-effects to the relation mechanism.
//         // Some additional testing is warranted.
//         annotationStore.setPdfAnnotations(
//           annotationStore.pdfAnnotations
//             .deleteAnnotation(annotation)
//             .withNewAnnotation(annotation.update({ label: selectedLabel }))
//         );
//         onHide();
//       }}
//       cancelButtonProps={{ onMouseDown }}
//       okButtonProps={{ onMouseDown }}
//       visible>
//       <Select<string>
//         value={selectedLabel.text}
//         onMouseDown={onMouseDown}
//         onChange={(labelText) => {
//           const label = annotationStore.labels.find((l) => l.text === labelText);
//           if (!label) {
//             return;
//           }
//           setSelectedLabel(label);
//         }}
//         style={{ display: 'block' }}>
//         {annotationStore.labels.map((l) => (
//           <Select.Option value={l.text} key={l.text}>
//             {l.text}
//           </Select.Option>
//         ))}
//       </Select>
//     </Modal>
//   );
// };

interface SelectionProps {
  // pageInfo: PDFPageInfo;
  // annotation: Annotation;
  annotation: {xyxy: number[], text: string};
  label: {text: string, color: string} | null,
  isActive: boolean;
  isHighlighted: boolean;
  highlightColor?: string;
  showInfo?: boolean;
  onClick: () => void;
  onToggleChecked: (event: React.MouseEvent<HTMLElement>) => void;
  onCopyText: (event: React.MouseEvent<HTMLElement>) => void;
}

export const Selection = ({ annotation, label, showInfo = true, isActive = false, isHighlighted, highlightColor, onClick, onToggleChecked, onCopyText }: SelectionProps) => {

  const [hovered, setHovered] = useState(false)
  const alwaysShowLabel = label !== null

  // const annotationStore = useContext(AnnotationStore);

  // let color;
  // if (!label) {
  //   color = theme.color.N4.hex; // grey as the default.
  // } else {
  //   color = label.color;
  // }

  // const bounds = pageInfo.getScaledBounds(annotation.bounds);
  // const bounds = annotation.bounds; // FIXME

  // const removeAnnotation = () => {
  //   annotationStore.setPdfAnnotations(
  //     annotationStore.pdfAnnotations.deleteAnnotation(annotation)
  //   );
  // };

  // const onShiftClick = () => {
  //   const current = annotationStore.selectedAnnotations.slice(0);
  //
  //   // Current contains this annotation, so we remove it.
  //   if (current.some((other) => other.id === annotation.id)) {
  //     const next = current.filter((other) => other.id !== annotation.id);
  //     annotationStore.setSelectedAnnotations(next);
  //     // Otherwise we add it.
  //   } else {
  //     current.push(annotation);
  //     annotationStore.setSelectedAnnotations(current);
  //   }
  // };

  // const selected = annotationStore.selectedAnnotations.includes(annotation);
  const hideLabels = !annotation.isLeading || !isActive; //&& !hovered;

  return (
    <div>
      <SelectionBoundary
        xyxy={annotation.xyxy}
        index={annotation.index}
        onClick={onClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        selected={isActive}
        highlighted={isHighlighted}
        highlightColor={highlightColor}
      >
      </SelectionBoundary>

      {alwaysShowLabel || (showInfo && !hideLabels) ? (
          <SelectionInfo xyxy={annotation.xyxy} selected={(showInfo && !hideLabels)}>
            {/*<span>{label?.text ?? "foo"}</span>*/}
            {alwaysShowLabel && (
              <span style={{color: label.color, fontSize: '0.75rem', fontWeight: 500}}>{label.text}</span>
            )}
            
            {(showInfo && !hideLabels) && (
            <>
              <IconButton onClick={onCopyText}><Copy size={18} /></IconButton>
              <IconButton onClick={console.log}><Link size={18}/></IconButton>
              <IconButton onClick={onToggleChecked}><SquareCheckBig size={18}/></IconButton>
            </>
            )}
            
            {/*<EditFilled*/}
            {/*  onClick={(e) => {*/}
            {/*    e.stopPropagation();*/}
            {/*    setIsEditLabelModalVisible(true);*/}
            {/*  }}*/}
            {/*  onMouseDown={(e) => {*/}
            {/*    e.stopPropagation();*/}
            {/*  }}*/}
            {/*/>*/}
            {/*<CloseCircleFilled*/}
            {/*  onClick={(e) => {*/}
            {/*    e.stopPropagation();*/}
            {/*    // removeAnnotation();*/}
            {/*  }}*/}
            {/*  // We have to prevent the default behaviour for*/}
            {/*  // the pdf canvas here, in order to be able to capture*/}
            {/*  // the click event.*/}
            {/*  onMouseDown={(e) => {*/}
            {/*    e.stopPropagation();*/}
            {/*  }}*/}
            {/*/>*/}
          </SelectionInfo>
        ) : null}
      {
        // NOTE: It's important that the parent element of the tokens
        // is the PDF canvas, because we need their absolute position
        // to be relative to that and not another absolute/relatively
        // positioned element. This is why SelectionTokens are not inside
        // SelectionBoundary.
        // annotation.tokens ? (
        //   <SelectionTokens pageInfo={pageInfo} tokens={annotation.tokens} />
        // ) : null
      }
      {/*{isEditLabelModalVisible ? (*/}
      {/*  <EditLabelModal*/}
      {/*    annotation={annotation}*/}
      {/*    onHide={() => setIsEditLabelModalVisible(false)}*/}
      {/*  />*/}
      {/*) : null}*/}
    </div>
  );
};

// We use transform here because we need to translate the label upward
// to sit on top of the bounds as a function of *its own* height,
// not the height of it's parent.
interface SelectionInfoProps {
  xyxy: number[];
  selected: boolean;
}
const SelectionInfo = styled.div<SelectionInfoProps>(
  ({ xyxy, selected }) => `
        position: absolute;
        left: ${xyxy[2]+2.5}px;
        top: ${xyxy[1]-3.5}px;
        border-radius: 5px;
        padding: 2px;
        transform:translateX(-100%) translateY(-100%);
        backdrop-filter: ${selected ? "blur(10px)" : "none"};
        font-weight: bold;
        font-size: 12px;
        user-select: none;

        display: flex;
        border: 1px solid ${selected ? "#d8dfe6" : "transparent"};

        * {
            padding: 2px;
            vertical-align: middle;
            border-radius: 2px;
        }
    `
);

const IconButton = styled.div`
  cursor: pointer;
  padding: 2px;
  border-radius: 2px;
  &:hover {
    background-color: #d8dfe677;
  }
  &:active > * {
    color: rgb(48, 118, 238);
    background-color: transparent;
  }
  padding-left: 5px;
  padding-right: 5px;
`;