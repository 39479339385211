"use client"
import { useParams } from "react-router-dom"

import { Folder } from "@/components"
import { ProjectProvider } from "@/context/projectContext"

export default function page() {
  const { id } = useParams()

  return (
    <ProjectProvider>
        <Folder folderId={id} />
    </ProjectProvider>
  )
}
