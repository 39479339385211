import { Input } from "@/components/ui/input"
import { CheckIcon, PencilIcon } from "lucide-react"
import { useState } from "react"

export function EditableField({
    value,
    onSave,
    children
  }: {
    value: string
    onSave: (value: string) => void
    children?: React.ReactNode
  }) {
    const [isEditing, setIsEditing] = useState(false)
    const [editedValue, setEditedValue] = useState(value)
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEditedValue(e.target.value)
    }
  
    const handleSave = () => {
      setIsEditing(false)
      onSave(editedValue)
    }
  
    if (!isEditing) {
      return <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center gap-2">
        {editedValue}
        <button className="p-0 m-0" onClick={() => setIsEditing(true)}>
          <PencilIcon className="w-4 h-4" />
        </button>
        {children}
      </dd>
    }
    
    return (
      <dd className="flex items-center gap-2">
        <Input type="text" value={editedValue} onChange={handleChange} />
        <button onClick={handleSave}>
          <CheckIcon className="w-4 h-4" />
        </button>
      </dd>
    )
  }