import { Link } from "react-router-dom"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import LoginIllustration from "@/assets/images/LoginIllustration.svg?react"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { useRequestResetPasswordEmailMutation } from "@/api/resources"
import { toast } from "sonner"

const forgotPasswordSchema = z.object({
  email: z.string().email({ message: "Please enter a valid email address" }),
})

function ForgotPasswordForm() {
  const [requestResetPasswordEmail] = useRequestResetPasswordEmailMutation()
  const form = useForm<z.infer<typeof forgotPasswordSchema>>({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: "",
    },
  })

  const onSubmit = async (values: z.infer<typeof forgotPasswordSchema>) => {
    const loadingToastId = toast.loading("Sending reset email...")
    try {
      await requestResetPasswordEmail(values.email).unwrap()
      toast.success("Reset email sent!", {
        description: "Please check your inbox for further instructions.",
        id: loadingToastId,
      })
    } catch (error) {
      toast.error("Failed to send reset email.", {
        description: "Please try again later.",
        id: loadingToastId,
      })
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid gap-4">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    placeholder="m@example.com"
                    type="email"
                    autoComplete="email"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" className="w-full">
            Send Reset Link
          </Button>
          <div className="text-center">
            <Link to="/login" className="text-sm underline">
              Back to login
            </Link>
          </div>
        </div>
      </form>
    </Form>
  )
}

export default function ForgotPasswordPage() {
  return (
    <div className="w-full lg:grid lg:min-h-screen lg:grid-cols-2">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-5xl font-300 font-outfit">Reset Password</h1>
            <p className="text-balance text-muted-foreground">
              Enter your email and we'll send you instructions to reset your password
            </p>
            <div className="pb-5" />
          </div>
          <ForgotPasswordForm />
        </div>
      </div>
      <div className="hidden bg-muted lg:block lg:h-full p-4">
        <div className="flex flex-col h-full p-10 bg-bgSecondary rounded-3xl">
          <LoginIllustration className="p-10 flex-grow-2 mx-auto" />
          <div className="flex-grow pl-10 flex-col justify-center">
            <h3 className="font-400 text-25 font-outfit">
              Welcome to Pactus <span className="text-blue-200">AI</span>!
            </h3>
            <p className="pt-2 text-text-neutral">
              Please enter your email address to reset your password.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
