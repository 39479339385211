import React, { createContext } from "react"
import {
  DocumentSchema,
  ParsedDocumentSchema,
  ProvisionKeys,
  SemanticSearchResult,
} from "@/api/resources"
import { DdCriterion } from "@/data/criteria"
import { DocumentPredictionsInfoSchema } from "@/api/resources/types"

export interface SemanticMatch {
  index: number // node index (not very nice)
  text: string // original text
  value: number // "probability"
  isUserValidated: number // 0 (false) or 1 (true)
}

interface DocumentPageState {
  documentId: string
  document: DocumentSchema | null
  htmlFile: string | null
  semanticMatches: SemanticMatch[] | null // UNUSED - only used in old HTML viewer
  allMatches: SemanticMatch[]
  semanticSearchClass: string | null // TODO: typescript error in page.tsx -> ProvisionKeys | null
  setSemanticSearchClass: (x: ProvisionKeys | string | null) => void
  // shared between keywords and semantic search
  activeMatch: number | null
  setActiveMatch: (x: number | null) => void
  keywordMatches: SemanticMatch[]
  //
  keywords: string[]
  setKeywords: (x: string[]) => void
  selectedClasses: DocumentPredictionsInfoSchema[]
  setSelectedClasses: (x: DocumentPredictionsInfoSchema[]) => void
  parsedDocument: ParsedDocumentSchema | null
  predictionsInfo: DocumentPredictionsInfoSchema[]
  //
  tab: "overview"|"edit"
  setTab: (x: "overview"|"edit") => void
  openDefaultTab: () => void
  openEditTab: (searchClass: string) => void
}

export function getColor(value: number, isActive: boolean) {
  console.log("color", value, isActive)
  if (value > 0.6) {
    return isActive ? "#C3ECC4" : "#EBF9EB"
  }
  if (value > 0.25) {
    return isActive ? "#F9E6DB" : "#F9F6EB"
  }
  return isActive ? "#FDC0C0" : "#FDF4F4"
}

const DocumentContext = createContext<DocumentPageState | null>(null)

export const useDocumentContext = () => {
  const ctx = React.useContext(DocumentContext)
  if (!ctx)
    throw new Error("Document state can't be null in useDocumentContext")
  return ctx
}

export default DocumentContext
