import { Icon } from "@/assets"
import { useState } from "react"
import { DeleteDocumentDialog } from "@/components/Documents/document/DeleteDocumentDialog"
import { RenameDocumentDialog } from "@/components/Documents/document/RenameDocumentDialog"
import { DocumentSchema, useAssignDocumentToPeopleMutation, useGetProjectCollaboratorsQuery, useReanalyzeDocumentsMutation, useSetDocumentStatusMutation } from "@/api/resources"
import Status, { DropdownValue } from "@/elements/status"
import { STATUS_DROPDOWN_VALUES } from "@/types/status"
import { findStatus } from "@/utils/status"

interface Props {
  document: DocumentSchema
}
// TODO: merge with FolderDropdown if there's no difference in buttons
//

import * as React from "react"
import { MoreHorizontal } from "lucide-react"
 
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import unidecode from "unidecode"
import ReanalyzeModal from "@/components/Project/reanalyze-modal"
 
const labels = [
  "feature",
  "bug",
  "enhancement",
  "documentation",
  "design",
  "question",
  "maintenance",
]
 

export default function DocumentDropdown({ document }: Props) {
  const [label, setLabel] = React.useState("feature")
  const [open, setOpen] = React.useState(false)
 
  // FIXME: put all this shit in a project context or something !!!
  const { data: collaborators } = useGetProjectCollaboratorsQuery(document.project_id)
  const [assignDocumentToPeople, { isLoading: isAssigning }] = useAssignDocumentToPeopleMutation()
  const [reanalyzeDocuments, { isLoading: isReanalyzing }] = useReanalyzeDocumentsMutation()
  
  // FIXME: we have a dialog for every list item - do this more efficiently!!!
  const [isRenamingDialog, setRenamingDialog] = useState(false)
  const [isDeletingDialog, setDeletingDialog] = useState(false)
  const [isReanalyzingDialog, setReanalyzingDialog] = useState(false)

  const initialStatus = document.status ? findStatus(document.status) : null
  const [selectedStatus, setSelectedStatus] =
    useState<DropdownValue>(initialStatus)
  const [setDocumentStatus, result] = useSetDocumentStatusMutation()

  const setStatusAndUpdate = (newStatus: DropdownValue) => {
    setSelectedStatus(newStatus)
    setDocumentStatus({
      document_id: document.document_id,
      status: newStatus.id,
    })
  }
  return (
    <DropdownMenu open={open} onOpenChange={setOpen} modal>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm">
          <MoreHorizontal />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[200px]" onClick={(e) => e.stopPropagation()}>
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuGroup>
          <DropdownMenuItem>Set due date...</DropdownMenuItem>
          <DropdownMenuItem onClick={() => {
            setOpen(false)
            setReanalyzingDialog(true)
          }}>Reanalyze...</DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>Assign to</DropdownMenuSubTrigger>
            <DropdownMenuSubContent className="p-0">
              <Command
                filter={(value, search) => {
                  // Convert both value and search to lowercase for case-insensitive comparison
                  const itemValue = unidecode(value).toLowerCase();
                  const searchTerm = unidecode(search).toLowerCase();
      
                  // Define custom matching logic
                  if (itemValue.includes(searchTerm)) {
                      return 1; // Full match
                  }
                  return 0; // No match
                }}
              >
                <CommandInput
                  placeholder="Filter people..."
                  autoFocus={true}
                  className="h-9"
                />
                <CommandList>
                  <CommandEmpty>No people found.</CommandEmpty>
                  <CommandGroup>
                    {collaborators?.map((collaborator) => (
                      <CommandItem
                        key={collaborator.email}
                        value={collaborator.first_name + " " + collaborator.last_name}
                        onSelect={(value) => {
                          assignDocumentToPeople({ document_id: document.document_id, user_ids: [collaborator.user_id] })
                          setOpen(false)
                        }}
                      >
                        {collaborator.first_name + " " + collaborator.last_name}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </DropdownMenuSubContent>
          </DropdownMenuSub>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => {
            setOpen(false)
            setRenamingDialog(true)
          }}>
            Rename
          </DropdownMenuItem>
          <DropdownMenuItem className="text-red-600" onClick={() => {
            setOpen(false)
            setDeletingDialog(true)
          }}>
            Delete
            {/* <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut> */}
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
      {document && (
        <>
          <RenameDocumentDialog
            document={document}
            open={isRenamingDialog}
            onOpenChange={setRenamingDialog}
          />
          <DeleteDocumentDialog
            document={document}
            open={isDeletingDialog}
            onOpenChange={setDeletingDialog}
          />
          <ReanalyzeModal
            documentId={document.document_id}
            open={isReanalyzingDialog}
            setOpen={setReanalyzingDialog}
            onSubmit={(run_ocr, run_class_identification) => {
              reanalyzeDocuments({ project_id: document.project_id, base_resource_id: document.document_id, run_ocr, run_class_identification })
            }}
          />
        </>
      )}
    </DropdownMenu>
  )
}


export function DocumentDropdownOld({ document }: Props) {
  const ICON_STYLE = "h-[20px] mr-1"
  const [isRenamingDialog, setRenamingDialog] = useState(false)
  const [isDeletingDialog, setDeletingDialog] = useState(false)
  const initialStatus = document.status ? findStatus(document.status) : null
  const [selectedStatus, setSelectedStatus] =
    useState<DropdownValue>(initialStatus)
  const [setDocumentStatus, result] = useSetDocumentStatusMutation()

  const setStatusAndUpdate = (newStatus: DropdownValue) => {
    setSelectedStatus(newStatus)
    setDocumentStatus({
      document_id: document.document_id,
      status: newStatus.id,
    })
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="focus:outline"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Icon name="More" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="py-2">
        <DropdownMenuLabel>Status</DropdownMenuLabel>
        <Status
          values={STATUS_DROPDOWN_VALUES}
          selectedValue={selectedStatus}
          setSelectedValue={setStatusAndUpdate}
        />
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="mb-1"
          onClick={() => {
            setRenamingDialog(true)
          }}
        >
          <Icon name="Edit" className={ICON_STYLE} />
          Rename
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            setDeletingDialog(true)
          }}
        >
          <Icon name="Delete" className={ICON_STYLE} />
          Delete
        </DropdownMenuItem>
      </DropdownMenuContent>
      {document && (
        <>
          <RenameDocumentDialog
            document={document}
            open={isRenamingDialog}
            onOpenChange={setRenamingDialog}
          />
          <DeleteDocumentDialog
            document={document}
            open={isDeletingDialog}
            onOpenChange={setDeletingDialog}
          />
        </>
      )}
    </DropdownMenu>
  )
}
