"use client"

import { useState } from "react"
import { ArrowDown, ArrowUp, ArrowUpDown, ChevronLeft, ChevronRight } from "lucide-react"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Button } from "@/components/ui/button"
import { Switch } from "@/components/ui/switch"

interface DataTableProps {
  data: {
    index: number[]
    user_label: number[]
    cv_label?: number[]
    predicted_probs?: number[]
    text: string[]
  }
  onLabelChange?: (index: number, newValue: number) => void
}

type SortDirection = "asc" | "desc" | null
type SortKey = keyof Omit<DataTableProps["data"], "index">

const COLUMNS = [
  { key: "text" as const, label: "TEXT" },
  { key: "user_label" as const, label: "USER LABEL" },
  { key: "cv_label" as const, label: "CV LABEL" },
  { key: "predicted_probs" as const, label: "MODEL CONFIDENCE" },
]

const ROWS_PER_PAGE = 50

export default function DataTable({ data, onLabelChange }: DataTableProps) {
  const [sortConfig, setSortConfig] = useState<{
    key: SortKey
    direction: SortDirection
  }>({
    key: "text",
    direction: null,
  })
  const [currentPage, setCurrentPage] = useState(1)

  // Convert array-based data into array of objects for easier sorting
  const tableData = data.text.map((_, idx) => ({
    originalIndex: data.index[idx], // Keep track of original index
    user_label: data.user_label[idx],
    cv_label: data.cv_label?.[idx],
    predicted_probs: data.predicted_probs?.[idx],
    text: data.text[idx],
  }))

  const sortedData = [...tableData].sort((a, b) => {
    if (sortConfig.direction === null) {
      return 0
    }

    const aValue = a[sortConfig.key]
    const bValue = b[sortConfig.key]

    // Skip sorting for undefined values
    if (aValue === undefined || bValue === undefined) {
      return 0
    }

    if (typeof aValue === "number" && typeof bValue === "number") {
      return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue
    }

    if (typeof aValue === "string" && typeof bValue === "string") {
      return sortConfig.direction === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
    }

    return 0
  })

  // Pagination calculations
  const totalPages = Math.ceil(sortedData.length / ROWS_PER_PAGE)
  const startIndex = (currentPage - 1) * ROWS_PER_PAGE
  const endIndex = Math.min(startIndex + ROWS_PER_PAGE, sortedData.length)
  const currentData = sortedData.slice(startIndex, endIndex)

  const requestSort = (key: SortKey) => {
    let direction: SortDirection = "asc"
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc"
    } else if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = null
    }
    setSortConfig({ key, direction })
    setCurrentPage(1) // Reset to first page when sorting
  }

  const getSortIcon = (key: SortKey) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUp className="ml-2 h-4 w-4" />
      }
      if (sortConfig.direction === "desc") {
        return <ArrowDown className="ml-2 h-4 w-4" />
      }
    }
    return <ArrowUpDown className="ml-2 h-4 w-4" />
  }

  const handleLabelToggle = (row: (typeof tableData)[0]) => {
    const newValue = row.user_label === 1 ? 0 : 1
    onLabelChange?.(row.originalIndex, newValue)
  }

  return (
    <div className="space-y-4">
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              {COLUMNS.map(({ key, label }) => (
                <TableHead key={key} className={key === "text" ? "w-1/2" : "w-1/6"}>
                  <Button
                    variant="ghost"
                    onClick={() => requestSort(key)}
                    className="flex items-center font-semibold hover:bg-transparent"
                  >
                    {label}
                    {getSortIcon(key)}
                  </Button>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {currentData.map((row) => (
              <TableRow key={row.originalIndex}>
                <TableCell className="whitespace-pre-wrap">{row.text}</TableCell>
                <TableCell>
                  <div className="flex flex-col items-center gap-2">
                    <span>{row.user_label}</span>
                    <Switch checked={row.user_label === 1} onCheckedChange={() => handleLabelToggle(row)} />
                  </div>
                </TableCell>
                <TableCell className="text-center">{row.cv_label === undefined ? "-" : row.cv_label}</TableCell>
                <TableCell className="text-center">
                  {row.predicted_probs === undefined ? "-" : `${(row.predicted_probs * 100).toFixed(1)}%`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <div className="flex items-center justify-between px-2">
        <div className="text-sm text-muted-foreground">
          Showing {startIndex + 1}-{endIndex} of {sortedData.length} rows
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            <ChevronLeft className="h-4 w-4" />
            Previous
          </Button>
          <div className="text-sm font-medium">
            Page {currentPage} of {totalPages}
          </div>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Next
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  )
}

