import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import {
  useDeleteDirectoryMutation,
  useDeleteDocumentMutation,
} from "@/api/resources"

export function DeleteDocumentDialog({ open, onOpenChange, document }) {
  const [deleteDocument, { isLoading: isDeleting }] =
    useDeleteDocumentMutation()

  const doDelete = () => {
    deleteDocument(document.document_id)
  }

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      {/*<AlertDialogTrigger asChild>*/}
      {/*  <Button variant="outline">Show Dialog</Button>*/}
      {/*</AlertDialogTrigger>*/}
      <AlertDialogContent onClick={(e) => e.stopPropagation()}>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete the
            document {document.display_name} and remove data from the server.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            variant="destructive"
            onClick={doDelete}
            disabled={isDeleting}
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
