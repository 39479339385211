import { Link, useLocation } from "react-router-dom"

import { Icon } from "@/assets"
import { IconNameEnum } from "@/types/icons"

const headerLinks = [
  {
    name: "Dashboard",
    icon: "Dashboard",
    link: "/dashboard",
  },
  {
    name: "Projects",
    icon: "Projects",
    link: "/projects",
  },
  {
    name: "Saved Searches",
    icon: "LibraryBlue",
    link: "/saved-searches",
  },
  {
    name: "Datasets",
    icon: "LibraryBlue",
    link: "/datasets",
  },
  {
    name: "Users",
    icon: "Users",
    link: "/admin/users",
  }
]

export default function Paths() {
  const { pathname: currentPath } = useLocation()

  return (
    <div className="flex justify-between gap-8">
      {headerLinks.map((link, _) => (
        <Link
          key={_}
          to={link.link}
          className={`flex justify-between items-center gap-[7px] font-500 text-14 leading-4 text-nowrap ${
            currentPath.startsWith(link.link) ? "active-link" : ""
          }
          link `}
        >
          {/* <Icon name={link.icon as keyof typeof IconNameEnum} /> */}
          {link.name}
        </Link>
      ))}
    </div>
  )
}
